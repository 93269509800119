import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import Event from "./Event";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../utils/date";
import {
  setDate,
  formatTimeDivision,
  isToday,
  cellHeight
} from "../../utils/calendar";
import {
  getMediaQuery,
  mediaQueryBreakpoints,
  useViewportHeight
} from "../../utils/responsive";
import SwipeableViews from "react-swipeable-views";

const styles = theme => ({
  div: {
    transition: "3s ease-out"
  },
  root: {
    width: "100%",
    overflowX: "auto"
  },
  division: {
    fontSize: "10px",
    height: 14,
    textAlign: "center"
  },
  caption: {
    fontSize: "10px"
  },
  mainHeader: {
    display: "flex",
    justifyContent: "flex-start"
  },
  weekNavigation: {
    padding: "10px"
  },
  mainBody: {
    display: "flex",
    overflow: "auto"
  },
  sidebar: {
    width: "10vw"
  },
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  daysName: {
    display: "flex",
    flexShrink: 0
  },
  dayTitleDivisions: {
    flex: 1,
    display: "flex",
    width: "100%",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      marginTop: "-50px",
      paddingBottom: 30,
      marginLeft: 55,
      width: "calc(100% - 55px)"
    }
  },
  content: {
    display: "flex"
  },
  box: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      overflow: "hidden"
    }
  },
  boxEachHalfHour: {
    width: "40px",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      borderRight: "1px solid #E5E5E5",
      paddingLeft: 27,
      paddingRight: 27
    }
  },
  boxEachDivision: {
    minHeight: "-webkit-min-content",
    display: "flex",
    flexDirection: "column"
  },
  halfHourTime: {
    fontSize: "11.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: cellHeight
  },
  halfHour: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dayName: {
    fontSize: "12px",
    textAlign: "center",
    textTransform: "uppercase"
  },
  daysTitle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      width: 55,
      flexDirection: "column",
      borderRight: "1px solid #E5E5E5",
      paddingBottom: 30
    }
  },
  dayNumberContainer: {},
  dayNumber: {
    fontSize: "17px",
    fontWeight: "bold",
    textAlign: "center",
    margin: "auto",
    paddingTop: 5,
    paddingBottom: 5
  },
  dayNumberToday: {
    flex: 1,
    display: "flex",
    color: "#fff",
    background: "#2986ff",
    fontSize: "17px",
    width: 30,
    height: 30,
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
});

const CalendarWeek = ({
  classes,
  divisions,
  events,
  t,
  selectedDate,
  onEventClick,
  onHalfHourClick,
  onSwipeDay,
  onLoadSwipe,
  displayableDaysIndex,
  swipeableViewsIndex
}) => {
  const onlyDisplayableDays = (unused, index) => {
    return displayableDaysIndex.length !== 0
      ? displayableDaysIndex.some(
          displayableIndex => displayableIndex === index
        )
      : true;
  };

  const renderDivisionTimeCells = (
    dayOfTheWeek,
    divisions,
    onHalfHourClick
  ) => {
    return divisions.map(division => {
      return (
        <div
          className={classes.boxEachDivision}
          key={division.id}
          style={{ width: `${(1 / divisions.length) * 100}%` }}
        >
          {formatTimeDivision().map(halfHour => (
            <div
              key={halfHour}
              className={classes.halfHour}
              style={{
                ...(formatDate(halfHour, "mm") === "00" && halfHour !== "06:00"
                  ? { borderBottom: "#dadce0 1px solid" }
                  : {}),
                height: cellHeight,
                width: "100%"
              }}
              onClick={
                isDesktop
                  ? () =>
                      onHalfHourClick({
                        dayOfTheWeek,
                        division,
                        halfHour
                      })
                  : null
              }
            />
          ))}
        </div>
      );
    });
  };

  const renderHours = () => {
    return formatTimeDivision().map(halfHour => {
      return (
        <div key={halfHour} className={classes.halfHourTime}>
          <Typography variant="caption" className={classes.halfHourTime}>
            {halfHour}
          </Typography>
        </div>
      );
    });
  };

  const isDesktop = getMediaQuery(mediaQueryBreakpoints.DESKTOP);
  const weekDaysDate = setDate(selectedDate);
  const viewport = useViewportHeight();
  const amountDaysToShow =
    displayableDaysIndex.length === 0 ? 7 : displayableDaysIndex.length;

  return (
    <Grid>
      {isDesktop ? (
        <React.Fragment>
          <div
            className={classes.mainBody}
            style={
              document.getElementById("menuContainer") && {
                height:
                  viewport -
                  (document.getElementById("menuContainer").offsetHeight +
                    40 +
                    48)
              }
            }
          >
            <div className={classes.main}>
              <div className={classes.daysName}>
                <div style={{ width: 40 }} />
                {divisions.length !== 0 &&
                  weekDaysDate
                    .filter(onlyDisplayableDays)
                    .map((dayOfTheWeek, index) => {
                      const dayNumberOfTheWeek = dayOfTheWeek.onlyDayNumber;
                      return (
                        <div
                          key={index}
                          className={classes.daysTitle}
                          style={{
                            width: `calc((100% - 33px) / ${amountDaysToShow})`
                          }}
                        >
                          <Typography variant="h5" className={classes.dayName}>
                            {t(dayOfTheWeek.translate).substring(0, 3)}
                          </Typography>
                          <div className={classes.dayNumberContainer}>
                            <Typography
                              variant="h5"
                              className={
                                isToday(dayOfTheWeek.day)
                                  ? classes.dayNumberToday
                                  : classes.dayNumber
                              }
                            >
                              <div>{dayNumberOfTheWeek}</div>
                            </Typography>
                          </div>
                          <div className={classes.dayTitleDivisions}>
                            {divisions.map((division, index) => {
                              return (
                                <div
                                  key={division + index}
                                  style={{
                                    width: `${(1 / divisions.length) * 100}%`
                                  }}
                                >
                                  <Typography
                                    key={index}
                                    variant="body1"
                                    className={classes.division}
                                  >
                                    {division.shortName === null ||
                                    division.shortName === ""
                                      ? division.name.slice(0, 4)
                                      : division.shortName.slice(0, 4)}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className={classes.content}>
                <div className={classes.box}>
                  <div className={classes.boxEachHalfHour}>{renderHours()}</div>
                  {weekDaysDate
                    .filter(onlyDisplayableDays)
                    .map((dayOfTheWeek, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          position: "relative",
                          borderRight: "#dadce0 1px solid",
                          width: `calc((100% - 33px) / ${amountDaysToShow})`,
                          height: 630
                        }}
                      >
                        {renderDivisionTimeCells(
                          dayOfTheWeek,
                          divisions,
                          onHalfHourClick
                        )}
                        {events
                          .filter(({ startDate, divisions }) => {
                            const parsedDateTime = DateTime.fromISO(startDate);
                            return (
                              parsedDateTime.day ===
                                dayOfTheWeek.dateTime.day &&
                              divisions.length !== 0
                            );
                          })
                          .map((event, index) => (
                            <Event
                              divisions={divisions}
                              event={event}
                              key={index}
                              onClick={() => onEventClick(event)}
                            />
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        divisions.length !== 0 && (
          <SwipeableViews
            axis={"x"}
            index={swipeableViewsIndex}
            action={() => onLoadSwipe()}
            onSwitching={onSwipeDay}
            id="topContainer"
            style={{
              scrollBehavior: "smooth",
              backgroundColor: "#c4c4c430",
              WebkitOverflowScrolling: "touch"
            }}
          >
            {weekDaysDate.map((dayOfTheWeek, index) => {
              const dayNumberOfTheWeek = dayOfTheWeek.onlyDayNumber;

              return (
                <div key={index}>
                  <div className={classes.daysTitle}>
                    <Typography variant="h5" className={classes.dayName}>
                      {t(dayOfTheWeek.translate).substring(0, 3)}
                    </Typography>
                    <div className={classes.dayNumberContainer}>
                      <Typography
                        variant="h5"
                        className={
                          isToday(dayOfTheWeek.day)
                            ? classes.dayNumberToday
                            : classes.dayNumber
                        }
                      >
                        <div>{dayNumberOfTheWeek}</div>
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.dayTitleDivisions}>
                    {divisions.map((division, index) => {
                      return (
                        <div
                          key={division + index}
                          style={{
                            width: `${(1 / divisions.length) * 100}%`
                          }}
                        >
                          <Typography
                            key={index}
                            variant="body1"
                            className={classes.division}
                          >
                            {division.shortName === null ||
                            division.shortName === ""
                              ? division.name.slice(0, 4)
                              : division.shortName.slice(0, 4)}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={classes.content}
                    style={{ backgroundColor: "#fafafa" }}
                  >
                    <div className={classes.box}>
                      <div className={classes.boxEachHalfHour}>
                        {renderHours()}
                      </div>
                      {weekDaysDate
                        .filter(onlyDisplayableDays)
                        .map((dayOfTheWeek, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              position: "relative",
                              borderRight: "#dadce0 1px solid",
                              width: "calc(100% - 33px)",
                              overflow: "hidden"
                            }}
                          >
                            {renderDivisionTimeCells(
                              dayOfTheWeek,
                              divisions,
                              onHalfHourClick
                            )}
                            {events
                              .filter(({ startDate, divisions }) => {
                                const parsedDateTime = DateTime.fromISO(
                                  startDate
                                );
                                return (
                                  parsedDateTime.day ===
                                    dayOfTheWeek.dateTime.day &&
                                  divisions.length !== 0
                                );
                              })
                              .map((event, index) => (
                                <Event
                                  divisions={divisions}
                                  event={event}
                                  key={index}
                                />
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </SwipeableViews>
        )
      )}
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(CalendarWeek));
