import React from "react";
import classNames from "classnames";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  withStyles,
  Drawer,
  IconButton,
  List,
  Divider
} from "@material-ui/core";
import MainListItems from "../components/listItems";
import { withTranslation } from "react-i18next";
import { getMediaQuery, mediaQueryBreakpoints } from "../utils/responsive";

const drawerWidth = 240;

const styles = theme => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  languages: {
    fontSize: "14px"
  },
  languagesHidden: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(1) * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(1) * 9
    }
  },
  drawerCompactIcon: {
    height: 55,
    width: 55,
    borderRadius: 0,
    backgroundColor: "#fafafa",
    borderBottom: "1px solid #E5E5E5",
    "& > span": {
      justifyContent: "flex-start",
      paddingLeft: 6
    },
    "&:hover": {
      backgroundColor: "#fafafa"
    }
  },
  drawerCompact: {
    "& .MuiDrawer-paperAnchorLeft": {
      width: "100%"
    }
  },
  menuDrawerCompactIcon: {
    flexGrow: 1,
    height: 55,
    borderRadius: 0,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 30
  },
  listItems: {
    paddingLeft: 14
  }
});

export default withTranslation()(
  withStyles(styles)(({ open, toggleDrawer, classes, i18n }) => {
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };
    const isDesktop = getMediaQuery(mediaQueryBreakpoints.DESKTOP);

    return isDesktop ? (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          )
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 10
            }}
          >
            <IconButton
              className={open ? classes.languages : classes.languagesHidden}
              aria-label="CA"
              onClick={() => changeLanguage("ca")}
            >
              CA
            </IconButton>
            <IconButton
              className={open ? classes.languages : classes.languagesHidden}
              aria-label="ES"
              onClick={() => changeLanguage("es")}
            >
              ES
            </IconButton>
          </div>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon
              style={{
                transform: `rotate(${open ? 0 : 180}deg)`
              }}
            />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems />
        </List>
      </Drawer>
    ) : (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "fixed",
            borderBottom: "1px solid #E5E5E5",
            height: 55,
            zIndex: 1,
            backgroundColor: "#fafafa"
          }}
          id="compactNav"
        >
          <IconButton
            onClick={toggleDrawer}
            className={classes.drawerCompactIcon}
          >
            <ChevronLeftIcon
              style={{
                transform: `rotate(${open ? 0 : 180}deg)`
              }}
            />
          </IconButton>
        </div>
        <SwipeableDrawer
          open={open}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          className={classes.drawerCompact}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 20
            }}
          >
            <IconButton
              className={open ? classes.languages : classes.languagesHidden}
              aria-label="CA"
              onClick={() => changeLanguage("ca")}
            >
              CA
            </IconButton>
            <IconButton
              className={open ? classes.languages : classes.languagesHidden}
              aria-label="ES"
              onClick={() => changeLanguage("es")}
            >
              ES
            </IconButton>
            <IconButton
              onClick={toggleDrawer}
              className={classes.menuDrawerCompactIcon}
            >
              <ChevronLeftIcon
                style={{
                  transform: `rotate(${open ? 0 : 180}deg)`
                }}
              />
            </IconButton>
          </div>
          <Divider />
          <List className={classes.listItems}>
            <MainListItems onClickAction={isDesktop ? null : toggleDrawer} />
          </List>
        </SwipeableDrawer>
      </React.Fragment>
    );
  })
);
