import React from "react";

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = { user: {}, isLoggedIn: false, checked: null };

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token) {
      this.setState({ isLoggedIn: true, user, checked: true });
    } else {
      this.setState({ checked: true });
    }
  }

  login = ({ token, user = {} }, next) => {
    if (token) {
      localStorage.setItem("token", token);
    }

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    this.setState({ isLoggedIn: true, user }, next);
  };

  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.setState({ isLoggedIn: false, user: {} });
  };

  updateUser = user => {
    this.setState({ user: { ...this.state.user, ...user } });
  };

  render() {
    const { login, logout, updateUser } = this;
    const { user, isLoggedIn, checked } = this.state;

    return (
      checked !== null && (
        <AuthContext.Provider
          value={{ user, isLoggedIn, login, logout, updateUser }}
        >
          {this.props.children}
        </AuthContext.Provider>
      )
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

function withAuthConsumer(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <AuthConsumer>
          {props => <WrappedComponent {...this.props} {...props} />}
        </AuthConsumer>
      );
    }
  };
}

export { AuthProvider, AuthConsumer, withAuthConsumer };
