import React from "react";
import { Typography } from "@material-ui/core";

function TabContainer({ children, dir, notDisplayable }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{ padding: 8 * 3, display: notDisplayable && "none" }}
    >
      {children}
    </Typography>
  );
}

export default TabContainer;
