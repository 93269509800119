import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import SideBar from "./SideBar";
import { mediaQueryBreakpoints, useViewportHeight } from "../utils/responsive";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
    overflow: "hidden",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      overflow: "hidden"
    }
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) * 3,
    overflow: "auto",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      padding: 24,
      paddingTop: 70,
      overflow: "auto"
    }
  },
  chartContainer: {
    marginLeft: -22
  },
  h5: {
    marginBottom: theme.spacing(1) * 2
  }
});

const Page = ({ children, classes }) => {
  const [open, setOpen] = useState(
    JSON.parse(localStorage.getItem("sidebarOpen"))
  );

  const toggleDrawer = () => {
    const nextOpen = !open;

    localStorage.setItem("sidebarOpen", JSON.stringify(nextOpen));
    setOpen(nextOpen);
  };

  return (
    <div className={classes.root} style={{ height: useViewportHeight() }}>
      <CssBaseline />
      <SideBar open={open} toggleDrawer={toggleDrawer} />
      <main
        id="mainContentPage"
        className={classes.content}
        style={{
          WebkitOverflowScrolling: "touch"
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default withStyles(styles)(Page);
