import { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const mediaQueryBreakpoints = {
  MOBILE: 576,
  TABLET: 768,
  DESKTOP: 992,
  RETINA: 1200,
  RETINAM: 1400,
  RETINAL: 1600
};

const getMediaQuery = breakPoint => {
  return useMediaQuery(`(min-width: ${breakPoint}px)`);
};

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(
    document.documentElement.clientHeight
  );

  useEffect(() => {
    window.addEventListener(
      "resize",
      setViewportHeight(document.documentElement.clientHeight)
    );
  }, []);

  return viewportHeight;
};

export { mediaQueryBreakpoints, getMediaQuery, useViewportHeight };
export default { mediaQueryBreakpoints, getMediaQuery, useViewportHeight };
