import isRepeatableEventValidated from "./repeatableEventValidation";

export default function isDataValidated(event) {
  const errors = [
    ...(event.divisions.length <= 0 || typeof event.divisions === "undefined"
      ? [{ field: "divisionsRequiredError" }]
      : []),
    ...(typeof event.selectedClub === "undefined" || event.selectedTeam === null
      ? [{ field: "selectedClubRequiredError" }]
      : []),
    ...(typeof event.selectedTeam === "undefined" || event.selectedTeam === null
      ? [{ field: "selectedTeamRequiredError" }]
      : [])
  ];

  return isRepeatableEventValidated({ event, errors });
}
