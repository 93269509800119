import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import {
  Clubs,
  ClubsUpsert,
  ClubsDetails,
  Facilities,
  FacilitiesDetails,
  FacilitiesUpsert,
  Modalities,
  ModalitiesUpsert,
  TeamsUpsert
} from "../pages/";

const MemberApp = () => {
  return (
    <Router>
      <Switch>
        <Route path="/instalaciones/crear" component={FacilitiesUpsert} />
        <Route path="/instalaciones/:id/editar" component={FacilitiesUpsert} />
        <Route path="/instalaciones/:id" component={FacilitiesDetails} />
        <Route path="/instalaciones" component={Facilities} />
        <Route path="/clubes/crear" component={ClubsUpsert} />
        <Route path="/clubes/:id/equipos/crear" component={TeamsUpsert} />
        <Route
          path="/clubes/:id/equipos/:teamId/editar"
          component={TeamsUpsert}
        />
        <Route path="/clubes/:id/editar" component={ClubsUpsert} />
        <Route path="/clubes/:id" component={ClubsDetails} />
        <Route path="/clubes" component={Clubs} />
        <Route path="/modalidades/crear" component={ModalitiesUpsert} />
        <Route path="/modalidades/:id/editar" component={ModalitiesUpsert} />
        <Route path="/modalidades" component={Modalities} />
        <Redirect to="/instalaciones" />
      </Switch>
    </Router>
  );
};

export default MemberApp;
