import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { HuePicker } from "react-color";
import Page from "components/Page";
import request from "utils/request";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  fab: {
    margin: theme.spacing(1)
  },
  tableContainer: {
    height: 320
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1) * 3
  }
});
class Clubs extends React.Component {
  state = {
    editing: false,
    name: "",
    shortName: "",
    color: "",
    zipCode: "",
    contactName1: "",
    contactEmail1: "",
    contactPhone1: "",
    contactName2: "",
    contactEmail2: "",
    contactPhone2: "",
    notes: "",
    allModalities: [],
    modalities: [],
    modalityId: "",
    secondaryModality: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.setState({ id }, this.getClub);
    this.getModalities();
  }

  getClub = () => {
    const { id } = this.state;
    this.getClubRequest = request(
      `Clubs/${id}?filter=${encodeURI(
        JSON.stringify({ include: "modalities" })
      )}`,
      { method: "GET" }
    );

    this.getClubRequest.promise.then(club =>
      this.setState({
        ...club,
        secondaryModality: [...club.modalities.map(modality => modality.id)]
      })
    );
  };

  getModalities = () => {
    this.getModalitiesRequest = request(`Modalities`, {
      method: "GET"
    });

    this.getModalitiesRequest.promise.then(allModalities =>
      this.setState({ allModalities })
    );
  };

  onSubmit = event => {
    event.preventDefault();

    this.upsertClubRequest = request("Clubs", {
      method: "PATCH",
      body: JSON.stringify({
        ...this.state,
        modalities: [
          ...this.state.allModalities.filter(
            modality => this.state.secondaryModality.indexOf(modality.id) > -1
          )
        ]
      })
    });

    this.upsertClubRequest.promise.then(() =>
      this.props.history.push("/clubes")
    );
  };

  onInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  handleChangeComplete = color => {
    this.setState({ color: color.hex });
  };

  onPrimaryModalityHandleChange = event => {
    this.onInputChange({
      target: { name: "modalityId", value: event.target.value }
    });
  };

  onSecondaryModalityHandleChange = event => {
    this.onInputChange({
      target: { name: "secondaryModality", value: event.target.value }
    });
  };

  render() {
    const { classes, t } = this.props;
    const {
      id,
      name,
      shortName,
      color,
      zipCode,
      contactName1,
      contactEmail1,
      contactPhone1,
      contactName2,
      contactEmail2,
      contactPhone2,
      notes,
      allModalities,
      modalityId,
      secondaryModality
    } = this.state;

    return (
      <Page>
        <Typography variant="h4" gutterBottom component="h1">
          {id ? "Editar" : "Crear"} {t("Entidad")}
        </Typography>
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12} md={6}>
            <form onSubmit={this.onSubmit} className={this.props.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor={"name"}>{t("Nombre")}</InputLabel>
                <Input
                  id={"name"}
                  name={"name"}
                  value={name}
                  onChange={this.onInputChange}
                  autoFocus
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="shortName">
                  {t("Nombre")} {t("Corto")}
                </InputLabel>
                <Input
                  id="shortName"
                  name="shortName"
                  value={shortName}
                  inputProps={{ maxLength: 4 }}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Grid container>
                <Grid item xs={6} md={4}>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="name">Color</InputLabel>
                    <Input
                      id="color"
                      name="color"
                      autoComplete="color"
                      value={color}
                      onChange={this.onInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  style={{
                    paddingLeft: 20,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div
                      style={{
                        backgroundColor: color === "" ? "#000000" : color,
                        borderRadius: "50%",
                        width: 40,
                        height: 40
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: 20, display: "flex", alignItems: "center" }}
                >
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <HuePicker
                      color={color}
                      onChangeComplete={this.handleChangeComplete}
                      width={206}
                    />
                  </div>
                </Grid>
              </Grid>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{t("Código Postal")}</InputLabel>
                <Input
                  id="zipCode"
                  name="zipCode"
                  autoComplete="zipCode"
                  value={zipCode}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Typography
                style={{ marginTop: 50 }}
                variant="h5"
                gutterBottom
                component="h1"
              >
                {t("Contacto 1")}
              </Typography>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">
                  {t("Nombre")} {t("Contacto 1")}
                </InputLabel>
                <Input
                  id="contactName1"
                  name="contactName1"
                  autoComplete="contactName1"
                  value={contactName1}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">Email {t("Contacto 1")}</InputLabel>
                <Input
                  id="contactEmail1"
                  name="contactEmail1"
                  autoComplete="contactEmail1"
                  value={contactEmail1}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">
                  {t("Teléfono Contacto 1")}
                </InputLabel>
                <Input
                  id="contactPhone1"
                  name="contactPhone1"
                  autoComplete="contactPhone1"
                  value={contactPhone1}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Typography
                style={{ marginTop: 50 }}
                variant="h5"
                gutterBottom
                component="h1"
              >
                {t("Contacto 2")}
              </Typography>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">{t("Nombre Contacto 2")}</InputLabel>
                <Input
                  id="contactName2"
                  name="contactName2"
                  autoComplete="contactName2"
                  value={contactName2}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">Email {t("Contacto 2")}</InputLabel>
                <Input
                  id="contactEmail2"
                  name="contactEmail2"
                  autoComplete="contactEmail2"
                  value={contactEmail2}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">
                  {t("Teléfono Contacto 2")}
                </InputLabel>
                <Input
                  id="contactPhone2"
                  name="contactPhone2"
                  autoComplete="contactPhone2"
                  value={contactPhone2}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Typography
                style={{ marginTop: 50 }}
                variant="h5"
                gutterBottom
                component="h1"
              >
                {t("Modalidades")}
              </Typography>

              <Grid container item xs={6} style={{ marginTop: 35 }}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>{t("Modalidad Principal")}</InputLabel>
                  <Select
                    value={modalityId}
                    onChange={this.onPrimaryModalityHandleChange}
                    input={<Input id="simple-select" />}
                  >
                    {allModalities
                      .filter(
                        modality =>
                          secondaryModality.indexOf(modality.id) === -1
                      )
                      .map(modality => (
                        <MenuItem key={modality.name} value={modality.id}>
                          <ListItemText primary={modality.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {modalityId && (
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    style={{ marginTop: 25 }}
                  >
                    <InputLabel>{t("Modalidades Secundarias")}</InputLabel>
                    <Select
                      multiple
                      value={secondaryModality}
                      onChange={this.onSecondaryModalityHandleChange}
                      input={<Input id="select-multiple-checkbox" />}
                      style={{ width: "100%" }}
                      renderValue={selected =>
                        selected
                          .map(
                            element =>
                              allModalities.find(
                                modality => modality.id === element
                              ).name
                          )
                          .join(", ")
                      }
                    >
                      {allModalities
                        .filter(modality => modalityId !== modality.id)
                        .map(modality => (
                          <MenuItem key={modality.name} value={modality.id}>
                            <Checkbox
                              checked={
                                secondaryModality.indexOf(modality.id) > -1
                              }
                            />
                            <ListItemText primary={modality.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{t("Notas")}</InputLabel>
                <Input
                  id="notes"
                  name="notes"
                  autoComplete="notes"
                  multiline={true}
                  rows={10}
                  value={notes}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
              >
                {id ? "Actualizar" : "Crear"} {t("Entidad")}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(Clubs)));
