import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Login from "../pages/Login";

const UnauthenticatedApp = () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  </Router>
);

export default UnauthenticatedApp;
