import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthConsumer } from "providers/AuthProvider";
import request from "utils/request";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(1) * 3,
    marginRight: theme.spacing(1) * 3,
    [theme.breakpoints.up(400 + theme.spacing(1) * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(1) * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1) * 3
  }
});

class Login extends Component {
  state = {
    email: "",
    password: ""
  };

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/instalaciones");
    }
  }

  login = credentials => {
    this.loginRequest = request("Users/login?include=USER", {
      method: "POST",
      body: JSON.stringify(credentials)
    });

    return this.loginRequest.promise.then(({ id, user }) => {
      this.props.history.push("/instalaciones");
      return { token: id, user };
    });
  };

  onInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  onSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    if (((!email || email === "") && !password) || password === "") return;

    this.login({ email, password }).then(this.props.login);
  };

  render() {
    const t = this.props.t;
    return (
      <main className={this.props.classes.main}>
        <CssBaseline />
        <Paper className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Iniciar Sesión")}
          </Typography>
          <form onSubmit={this.onSubmit} className={this.props.classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">{t("Correo Electrónico")}</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.onInputChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{t("Contraseña")}</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.onInputChange}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={this.props.classes.submit}
            >
              {t("Iniciar Sesión")}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRouter(withAuthConsumer(Login)))
);
