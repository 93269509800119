import { DateTime } from "luxon";
import { cellHeight } from "./calendar";

const getEventPosition = (event = {}, facilityDivisions = []) => {
  const divisionsPercentage = 100 / facilityDivisions.length;
  const firstDivision = facilityDivisions
    .sort((a, b) => a.rank - b.rank)
    .findIndex(({ id }) => id === event.divisions[0].id);
  const parsedStartDate = DateTime.fromISO(event.startDate);
  const duration = DateTime.fromISO(event.endDate)
    .diff(parsedStartDate, "hours")
    .toObject();

  return {
    top:
      (parsedStartDate.hour + parsedStartDate.minute / 60 - 6) * cellHeight * 2,
    left: `${(firstDivision * divisionsPercentage).toFixed()}%`,
    height: cellHeight * duration.hours * 2,
    width: `${event.divisions.length * divisionsPercentage.toFixed()}%`
  };
};

export default getEventPosition;
