import React, { useState } from "react";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControl,
  InputLabel,
  Input,
  TextField
} from "@material-ui/core";
import TabContainer from "./TabContainer";
import {Select} from "ui";
import isDataValidated from "../../../utils/freeValidation";
import RepeatableSwitch from "./RepeatableSwitch";
import { formatDate } from "utils/date";
import { DateTime } from "luxon";

function TabContainerFree({
  type,
  theme,
  onParentSubmit,
  onStartHourChange,
  onEndHourChange,
  onInputChange,
  onDayClick,
  handleClose,
  requiredError,
  startDateSubmitted,
  startDateSubmittedHour,
  endDateSubmitted,
  endDateSubmittedHour,
  id,
  startDate,
  endDate,
  availableStates,
  eventState,
  divisions,
  freeEventName,
  eventNotes,
  freeEventRequiredError,
  freeEventDisabledContent,
  renderDivisionSelect,
  days,
  renderDeleteEvent,
  isRepeatable,
  renderRepeatableForm,
  repetitionEndDate,
  repeatDays,
  t,
  setSubmitButtonDisabled,
  submitButtonDisabled
}) {
  const [
    showRepeatableUpdateOptions,
    setShowRepeatableUpdateOptions
  ] = useState(false);
  const [disabledUpdateOptions, setDisabledUpdateOptions] = useState(false);
  const wasRepeatable = type === "repeatableTrain" || type === "repeatableFree";

  const onSubmitCheck = e => {
    e.preventDefault();
    if (isRepeatable && id && wasRepeatable) {
      setSubmitButtonDisabled(true);
      setShowRepeatableUpdateOptions(true);
    } else if (!isRepeatable) {
      repeatDays = null;
      repetitionEndDate = null;
      onSubmit({ e });
    } else {
      onSubmit({ e });
    }
  };

  const onSubmit = ({
    e,
    isolated = false,
    modifyAll = false,
    fromNowOn = false
  }) => {
    setDisabledUpdateOptions(true);
    const startDateToCompare = DateTime.fromFormat(
      startDateSubmitted,
      "yyyy-MM-dd"
    );

    const repetitionEndDateToCompare = repetitionEndDate
      ? DateTime.fromFormat(repetitionEndDate, "yyyy-MM-dd")
      : "";

    const event = {
      divisions,
      eventState,
      freeEventName,
      eventNotes,
      ...(isRepeatable
        ? { repetitionEndDate, repeatDays, repetitionEndDateToCompare }
        : {}),
      startDateToCompare
    };
    const dataValidation = isDataValidated(event);

    if (dataValidation.valid) {
      setSubmitButtonDisabled(true);
      onParentSubmit({
        event: e,
        calendarEvent: {
          id,
          ...(isolated ? { isolated } : {}),
          ...(modifyAll ? { modifyAll } : {}),
          ...(fromNowOn ? { fromNowOn } : {}),
          ...(isolated || fromNowOn
            ? {
                date:
                  formatDate(startDateSubmitted, "yyyy-MM-dd'T'") +
                  startDateSubmittedHour
              }
            : {}),
          startDate:
            formatDate(startDateSubmitted, "yyyy-MM-dd'T'") +
            startDateSubmittedHour,
          endDate:
            formatDate(endDateSubmitted, "yyyy-MM-dd'T'") +
            endDateSubmittedHour,
          divisions,
          eventState: eventState,
          freeEventName,
          eventNotes,
          type: repeatDays ? "repeatableFree" : "free",
          ...(isRepeatable
            ? {
                repeatDays,
                repetitionEndDate:
                  repetitionEndDate === "Invalid DateTime"
                    ? null
                    : repetitionEndDate
              }
            : {})
        }
      });
    } else {
      toggleErrors(
        freeEventRequiredError,
        dataValidation.errors,
        requiredError
      );
    }
  };

  return (
    <TabContainer
      dir={theme.direction}
      notDisplayable={freeEventDisabledContent}
    >
      <DialogTitle id="form-dialog-title">
        {id ? "Editar" : "Crear"} {t("Evento")}{" "}
        {formatDate(startDateSubmitted, "dd/MM/yyyy")}
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "hidden", minHeight: 500, minWidth: 550 }}
      >
        <form onSubmit={e => onSubmit({ e })}>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  name="startDate"
                  type="time"
                  label={t("Hora Inicio")}
                  value={formatDate(startDate, "HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: { step: 900, min: "06:00", max: "22:45" }
                  }}
                  onChange={onStartHourChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  name="endDate"
                  type="time"
                  label={t("Hora Fin")}
                  value={formatDate(endDate, "HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      step: 900,
                      min: formatDate(startDate, "HH:mm"),
                      max: "23:00"
                    }
                  }}
                  onChange={onEndHourChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {renderDivisionSelect("freeEventRequiredError", divisions)}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t("Estado")}</InputLabel>
              <Select
                options={availableStates}
                value={
                  eventState
                    ? {
                        value: eventState,
                        label: t(
                          availableStates.find(
                            availableState =>
                              availableState.value === eventState
                          ).label
                        )
                      }
                    : null
                }
                placeholder={t("Selecciona un estado...")}
                onChange={value =>
                  onInputChange({
                    target: {
                      name: "eventState",
                      value: value ? value.value : ""
                    }
                  })
                }
                isClearable
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel
                  htmlFor="freeEventName"
                  error={freeEventRequiredError.freeEventNameRequiredError}
                >
                  {t("Evento")}
                </InputLabel>
                <Input
                  id="freeEventName"
                  name="freeEventName"
                  autoComplete="freeEventName"
                  value={freeEventName}
                  onChange={onInputChange}
                  error={freeEventRequiredError.freeEventNameRequiredError}
                  required
                />
                {freeEventRequiredError.freeEventNameRequiredError && (
                  <FormHelperText style={{ color: "#F44336" }}>
                    {t("Campo requerido")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="eventNotes">{t("Notas")}</InputLabel>
                <Input
                  name="eventNotes"
                  autoComplete="eventNotes"
                  multiline={true}
                  rows={6}
                  value={eventNotes}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <RepeatableSwitch
              onInputChange={onInputChange}
              onDayClick={onDayClick}
              days={days}
              isRepeatable={isRepeatable}
              renderRepeatableForm={renderRepeatableForm}
              repetitionEndDate={repetitionEndDate}
              fieldRequiredError={freeEventRequiredError}
              t={t}
              startDate={startDateSubmitted}
              wasRepeatable={wasRepeatable}
            />
          </Grid>
        </form>
      </DialogContent>
      {showRepeatableUpdateOptions && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={e => {
              const isolated = true;
              return onSubmit({ e, isolated });
            }}
            disabled={disabledUpdateOptions}
          >
            {t("Solo este")}
          </Button>
          <Button
            onClick={e => {
              const fromNowOn = true;
              return onSubmit({ e, fromNowOn });
            }}
            disabled={disabledUpdateOptions}
          >
            {t("Este y posteriores")}
          </Button>
          <Button
            onClick={e => {
              const modifyAll = true;
              return onSubmit({ e, modifyAll });
            }}
            disabled={disabledUpdateOptions}
          >
            {t("Todos")}
          </Button>
        </div>
      )}
      <DialogActions>
        {renderDeleteEvent()}
        <Button onClick={handleClose} color="primary">
          {t("Cancelar")}
        </Button>
        <Button
          disabled={submitButtonDisabled}
          onClick={onSubmitCheck}
          type="submit"
          color="primary"
        >
          {id ? t("Actualizar") : "Crear"}
        </Button>
      </DialogActions>
    </TabContainer>
  );
}

function toggleErrors(freeEventRequiredError, errors, requiredError) {
  const freeEventRequiredErrorMuted = Object.keys(freeEventRequiredError)
    .map(key => {
      const isInErrors = errors.findIndex(error => error.field === key) > -1;
      return { [key]: isInErrors };
    })
    .reduce((reducer, item) => {
      return { ...reducer, ...item };
    }, {});

  requiredError({ freeEventRequiredError: freeEventRequiredErrorMuted });
}

export default TabContainerFree;
