import React from "react";
import {
  Button,
  FormHelperText,
  InputLabel,
  Dialog,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import request from "utils/request";
import { Select } from "ui";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import TabContainer from "./TabContainer";
import TabContainerTrain from "./TabContainerTrain";
import TabContainerMatch from "./TabContainerMatch";
import TabContainerFree from "./TabContainerFree";
import { addQuarterHour, plusHour, formatDate } from "utils/date";
import { isNullOrEmpty } from "utils/calendar";
import { getItemsWithoutDuplication } from "utils/reducers";
import { withTranslation } from "react-i18next";

const mapForSelect = props => ({
  value: props.id,
  label: props.name,
  ...props
});

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  dialogMigrationAdaption: {
    "& .MuiGrid-item": {
      paddingTop: 8,
      paddingBottom: 12
    }
  }
});

const eventTypes = [
  "train",
  "repeatableTrain",
  "match",
  "free",
  "repeatableFree"
];

const repetitionFieldErrors = {
  repeatDaysRequiredError: false,
  repetitionEndDateRequiredError: false,
  repetitionEndDateInvalidError: false
};

class EventDialog extends React.Component {
  state = {
    value: this.props.event.type
      ? this.props.event.type.toLowerCase().includes("train")
        ? 0
        : this.props.event.type === "match"
        ? 1
        : 2
      : 0,
    trainDisabled: false,
    matchDisabled: false,
    freeEventDisabled: false,
    trainDisabledContent: false,
    matchDisabledContent: false,
    freeEventDisabledContent: false,
    repeatDays: null,
    repetitionEndDate: null,
    isRepeatable: false,
    type: "train",
    ...this.props.event,
    teams: [],
    divisions: this.props.event.divisions.map(mapForSelect),
    eventState: this.props.event.eventState,
    days: [
      { name: "L", active: false },
      { name: "M", active: false },
      { name: "X", active: false },
      { name: "J", active: false },
      { name: "V", active: false },
      { name: "S", active: false },
      { name: "D", active: false }
    ].map((day, index) => ({
      ...day,
      active:
        this.props.event.repeatDays &&
        this.props.event.repeatDays.indexOf(index + 1) !== -1
    })),
    freeEventName: this.props.event.freeEventName
      ? this.props.event.freeEventName
      : "",
    eventNotes: this.props.event.eventNotes ? this.props.event.eventNotes : "",
    trainFieldRequiredError: {
      selectedClubRequiredError: false,
      selectedTeamRequiredError: false,
      divisionsRequiredError: false,
      ...repetitionFieldErrors
    },
    matchFieldRequiredError: {
      selectedClubRequiredError: false,
      selectedVisitorClubRequiredError: false,
      selectedTeamRequiredError: false,
      selectedVisitorTeamRequiredError: false,
      divisionsRequiredError: false,
      categoryRequiredError: false
    },
    freeEventRequiredError: {
      selectedTeamRequiredError: false,
      divisionsRequiredError: false,
      freeEventNameRequiredError: false,
      ...repetitionFieldErrors
    },
    showDeleteConfirmation: false,
    submitButtonDisabled: false
  };
  locale = "es";
  setLocale = "es";
  startDateSubmitted = "";
  endDateSubmitted = "";
  startDateSubmittedHour = "";
  endDateSubmittedHour = "";
  availableStates = [
    { value: "ok", label: "Ok" },
    { value: "anulado", label: "Anulado" },
    { value: "no presentado", label: "No Presentado" }
  ];

  componentDidMount() {
    this.getClubsAndTeams();

    this.startDateSubmitted = formatDate(this.state.startDate, "yyyy-MM-dd");
    this.endDateSubmitted = formatDate(this.state.endDate, "yyyy-MM-dd");
    this.startDateSubmittedHour = formatDate(this.state.startDate, "HH:mm");
    this.endDateSubmittedHour = formatDate(this.state.endDate, "HH:mm");

    this.setState({
      ...this.setTabsContentDisable(),
      ...this.setTabsDisable()
    });
  }

  setTabsDisable = (value = this.state.value) => {
    const tabs = {
      trainDisabled: false,
      matchDisabled: false,
      freeEventDisabled: false
    };
    switch (value) {
      case 0:
        if (this.state.id) {
          tabs.trainDisabled = false;
          tabs.matchDisabled = true;
          tabs.freeEventDisabled = true;
        }
        break;
      case 1:
        tabs.trainDisabled = true;
        tabs.freeEventDisabled = true;
        break;
      case 2:
        tabs.trainDisabled = true;
        tabs.matchDisabled = true;
        break;
      default:
    }
    return tabs;
  };

  setTabsContentDisable = (value = this.state.value) => {
    const content = {
      trainDisabledContent: false,
      matchDisabledContent: true,
      freeEventDisabledContent: true
    };
    switch (value) {
      case 0:
        content.trainDisabledContent = false;
        content.matchDisabledContent = true;
        content.freeEventDisabledContent = true;
        break;
      case 1:
        content.trainDisabledContent = true;
        content.matchDisabledContent = false;
        content.freeEventDisabledContent = true;
        break;
      case 2:
        content.trainDisabledContent = true;
        content.matchDisabledContent = true;
        content.freeEventDisabledContent = false;
        break;
      default:
    }
    return content;
  };

  tabsHandleChange = (event, value) => {
    this.setState({ value, ...this.setTabsContentDisable(value) });
  };

  tabsHandleChangeIndex = index => {
    this.setState({ value: index, type: eventTypes[index] });
  };

  getClubsAndTeams = () => {
    this.teamsRequest = request(
      `Teams?filter=${encodeURI(JSON.stringify({ include: "club" }))}`
    );

    this.teamsRequest.promise.then(teams => {
      const clubsAndTeams = teams.reduce((reducer, currentValue) => {
        const reducerArray = reducer[0];

        const clubFormat = {
          value: currentValue.club.id,
          label: currentValue.club.name,
          ...currentValue
        };

        const teamFormat = {
          value: currentValue.id,
          label: currentValue.name,
          ...currentValue
        };

        return [
          {
            teams: [
              ...(!isNullOrEmpty(reducerArray) ? reducerArray.teams : []),
              teamFormat
            ],
            clubs: [
              ...(!isNullOrEmpty(reducerArray) ? reducerArray.clubs : []),
              clubFormat
            ]
          }
        ];
      }, []);

      return this.setState({
        teams: clubsAndTeams[0].teams,
        clubs: getItemsWithoutDuplication({
          array: clubsAndTeams[0].clubs,
          prop: "value",
          sort: true
        })
      });
    });
  };

  onInputChange = ({ target }, checked) => {
    this.setState({
      [target.name]: typeof checked !== "undefined" ? checked : target.value
    });
  };

  requiredError = errors => {
    this.setState(errors);
  };

  onStartHourChange = ({ target }) => {
    const targetTime = target.value;
    this.startDateSubmittedHour = targetTime;
    const targetHour = formatDate(targetTime, "HH");
    const targetMinutes = formatDate(targetTime, "mm");
    const endHour = formatDate(this.state.endDate, "HH");
    const endMinutes = formatDate(this.state.endDate, "mm");

    if (targetHour === endHour && targetMinutes >= endMinutes) {
      const endDate = addQuarterHour(targetTime);
      this.endDateSubmittedHour = endDate;
      this.setState({ endDate });
    }

    if (targetHour > endHour) {
      const hour = plusHour(targetHour);

      this.endDateSubmittedHour = hour;
      this.setState({ endDate: hour });
    }

    this.onInputChange({ target });
  };

  onEndHourChange = ({ target }) => {
    const targetTime = target.value;
    const targetHour = formatDate(targetTime, "HH");
    const targetMinutes = formatDate(targetTime, "mm");
    const startHour = formatDate(this.state.startDate, "HH");
    const startMinutes = formatDate(this.state.startDate, "mm");

    if (targetHour === startHour && targetMinutes <= startMinutes) {
      const endDate = addQuarterHour(targetTime);

      this.endDateSubmittedHour = endDate;
      this.setState({ endDate });
    } else {
      this.endDateSubmittedHour = targetTime;
      this.onInputChange({ target });
    }
  };

  onDayClick = selectedDayIndex => {
    const days = this.state.days.map(({ active, ...props }, index) => ({
      active: selectedDayIndex === index ? !active : active,
      ...props
    }));

    const repeatDays = days
      .map((day, index) => ({ ...day, index: index + 1 }))
      .filter(({ active }) => active)
      .map(({ index }) => index)
      .join(",");

    this.setState({
      days,
      repeatDays
    });
  };

  setSubmitButtonDisabled = (disabled = false, cb = () => {}) => {
    this.setState({ submitButtonDisabled: disabled }, cb);
  };

  componentWillUnmount() {
    if (this.teamsRequest) this.teamsRequest.cancel();
  }

  onDeleteButtonClick = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  render() {
    const { onSubmit, handleClose, theme, t } = this.props;
    const {
      value,
      trainDisabled,
      matchDisabled,
      freeEventDisabled,
      trainDisabledContent,
      matchDisabledContent,
      freeEventDisabledContent
    } = this.state;

    const tabProps = {
      ...this.state,
      onStartHourChange: this.onStartHourChange,
      onEndHourChange: this.onEndHourChange,
      startDateSubmitted: this.startDateSubmitted,
      renderDivisionSelect: this.renderDivisionSelect,
      onInputChange: this.onInputChange,
      requiredError: this.requiredError,
      startDateSubmittedHour: this.startDateSubmittedHour,
      endDateSubmitted: this.endDateSubmitted,
      endDateSubmittedHour: this.endDateSubmittedHour,
      onDayClick: this.onDayClick,
      theme: theme,
      onParentSubmit: onSubmit,
      handleClose: handleClose,
      renderDeleteEvent: this.renderDeleteEvent,
      t: t,
      availableStates: this.availableStates.map(availableState => ({
        ...availableState,
        label: t(availableState.label)
      })),
      setSubmitButtonDisabled: this.setSubmitButtonDisabled
    };

    return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={this.props.classes.dialogMigrationAdaption}
        style={{ maxHeight: "812px" }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.tabsHandleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label={t("Entreno")} disabled={trainDisabled} />
            <Tab label={t("Partido")} disabled={matchDisabled} />
            <Tab label={t("Evento")} disabled={freeEventDisabled} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={this.tabsHandleChangeIndex}
        >
          {trainDisabledContent ? (
            <React.Fragment />
          ) : (
            <TabContainerTrain
              {...tabProps}
              trainDisabledContent={trainDisabledContent}
            />
          )}
          {matchDisabledContent ? (
            <React.Fragment />
          ) : (
            <TabContainerMatch
              {...tabProps}
              matchDisabledContent={matchDisabledContent}
            />
          )}
          {freeEventDisabledContent ? (
            <React.Fragment />
          ) : (
            <TabContainerFree
              {...tabProps}
              freeEventDisabledContent={freeEventDisabledContent}
            />
          )}
        </SwipeableViews>
      </Dialog>
    );
  }

  renderDivisionSelect = (
    requiredError,
    divisions = this.state.divisions,
    t = this.props.t
  ) => (
    <React.Fragment>
      <InputLabel error={this.state[requiredError].divisionsRequiredError}>
        {t("Divisiones")}
      </InputLabel>
      <div style={{ display: "flex" }}>
        <Select
          options={this.props.divisions.map(mapForSelect)}
          value={divisions}
          placeholder={t("Selecciona las divisiones...")}
          onChange={value =>
            this.onInputChange({
              target: { name: "divisions", value }
            })
          }
          error={this.state[requiredError].divisionsRequiredError}
          isMulti
        />
        <Button
          style={{ marginLeft: 10 }}
          onClick={() =>
            this.onInputChange({
              target: {
                name: "divisions",
                value: this.props.divisions.map(mapForSelect)
              }
            })
          }
        >
          {t("Todas")}
        </Button>
      </div>
      {this.state[requiredError].divisionsRequiredError && (
        <FormHelperText style={{ color: "#F44336" }}>
          {t("Campo requerido")}
        </FormHelperText>
      )}
    </React.Fragment>
  );

  renderDeleteEvent = () => {
    const {
      id,
      startDate,
      type,
      showDeleteConfirmation,
      submitButtonDisabled
    } = this.state;
    const { onDelete, t } = this.props;
    const isRepeatable =
      type === "repeatableTrain" || type === "repeatableFree";

    return (
      id && (
        <React.Fragment>
          <Button
            onClick={this.onDeleteButtonClick}
            color="secondary"
            style={{ marginRight: "auto" }}
            disabled={showDeleteConfirmation}
          >
            {showDeleteConfirmation
              ? isRepeatable
                ? t("¿Todos?")
                : t("¿Seguro?")
              : "Eliminar"}
          </Button>
          {showDeleteConfirmation && (
            <React.Fragment>
              <Button
                onClick={() =>
                  this.setSubmitButtonDisabled(true, onDelete({ id }))
                }
                color="secondary"
                style={{ marginRight: "auto" }}
                disabled={submitButtonDisabled}
              >
                Si
              </Button>
              {!isRepeatable && (
                <Button
                  onClick={() =>
                    this.setState({ showDeleteConfirmation: false })
                  }
                  color="default"
                  style={{ marginRight: "auto" }}
                  disabled={submitButtonDisabled}
                >
                  No
                </Button>
              )}
              {isRepeatable && (
                <Button
                  onClick={() =>
                    this.setSubmitButtonDisabled(
                      true,
                      onDelete({ id, date: startDate })
                    )
                  }
                  color="secondary"
                  style={{ marginRight: "auto" }}
                  disabled={submitButtonDisabled}
                >
                  {t("Solo este")}
                </Button>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    );
  };
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(EventDialog)
);
