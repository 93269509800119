export default function isRepeatableEventValidated({ event, errors }) {
  const isNull = inputValue => inputValue === null;
  const isEmpty = inputValue => inputValue === "";
  const isInvalidDateTime = inputValue => inputValue === "Invalid DateTime";

  const repeatDaysRequired = event =>
    (isEmpty(event.repeatDays) || isNull(event.repeatDays)) &&
    (!isEmpty(event.repetitionEndDate) &&
      !isNull(event.repetitionEndDate) &&
      !isInvalidDateTime(event.repetitionEndDate))
      ? [{ field: "repeatDaysRequiredError" }]
      : [];

  const repetitionEndDateRequired = event =>
    !isEmpty(event.repeatDays) &&
    !isNull(event.repeatDays) &&
    (isEmpty(event.repetitionEndDate) ||
      isNull(event.repetitionEndDate) ||
      isInvalidDateTime(event.repetitionEndDate))
      ? [{ field: "repetitionEndDateRequiredError" }]
      : [];

  const repetitionEndDateInvalid = event =>
    !isEmpty(event.repetitionEndDateToCompare) &&
    event.repetitionEndDateToCompare <= event.startDateToCompare
      ? [{ field: "repetitionEndDateInvalidError" }]
      : [];

  const repeatErrors = [
    ...errors,
    ...repeatDaysRequired(event),
    ...repetitionEndDateRequired(event),
    ...repetitionEndDateInvalid(event)
  ];

  return {
    valid: repeatErrors.length === 0,
    errors: repeatErrors
  };
}
