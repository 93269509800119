import React, { Component } from "react";
import { SnackbarProvider } from "notistack";
import { AuthProvider, AuthConsumer } from "./providers/AuthProvider";
import { MemberApp, UnauthenticatedApp } from "./components";

class App extends Component {
  render() {
    return (
      <SnackbarProvider maxSnack={1} style={{ marginBottom: "15%" }}>
        <AuthProvider>
          <AuthConsumer>
            {({ isLoggedIn }) =>
              isLoggedIn ? <MemberApp /> : <UnauthenticatedApp />
            }
          </AuthConsumer>
        </AuthProvider>
      </SnackbarProvider>
    );
  }
}

export default App;
