const BASE_API_URL = process.env.REACT_APP_API_URL;

function request(url, options = { method: "GET" }) {
  return cancellablePromise(
    fetch(BASE_API_URL + url, {
      ...options,
      headers: {
        "Accept-Language": "application/json",
        "Content-Type": "application/json",
        authorization:
          typeof localStorage !== "undefined" && localStorage.getItem("token"),
        ...options.headers
      }
    }).then(res => {
      switch (res.status) {
        case 200:
          return res.json();
        case 204:
          return;
        case 401:
          if (window.location.pathname !== "/login") {
            window.location = "/login";
          }
          localStorage.removeItem("token");
          return res.json().then(error =>
            Promise.reject({
              status: res.status,
              error
            })
          );

        default:
          return res.json().then(({ error }) =>
            Promise.reject({
              status: res.status,
              error
            })
          );
      }
    })
  );
}

const cancellablePromise = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
};

export default request;
