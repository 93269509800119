export const buildWhereFilter = (headers, search) =>
  headers
    .filter(({ isSearchable = false }) =>
      typeof isSearchable !== "undefined" ? isSearchable : true
    )
    .map(({ property, isSearchable }) =>
      typeof isSearchable === "function"
        ? { [property]: isSearchable(search) }
        : { [property]: { like: `%${search}%` } }
    );

export const updateLoopbackFilter = ({
  filter,
  headers,
  search = "",
  order,
  offset
}) => {
  return {
    ...filter,
    ...(search !== ""
      ? { where: { or: buildWhereFilter(headers, search) } }
      : { where: {} }),
    order,
    offset
  };
};
