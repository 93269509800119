import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  OutlinedInput,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
  Grid,
  Typography,
  Checkbox,
  ListItemText,
  Fab
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, MoreVert, Close } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { withTranslation } from "react-i18next";
import { PictureAsPdfOutlined, FilterList } from "@material-ui/icons/";
import PdfModal from "ui/PdfModal";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { pickTextColorBasedOnBgColorSimple } from "../../utils/calendar";
import { getMediaQuery, mediaQueryBreakpoints } from "../../utils/responsive";

const styles = theme => ({
  headerContainer: {
    display: "flex"
  },
  dayContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  selectMenu: {
    "& > div": {
      paddingTop: 0,
      paddingBottom: 0,
      "& > div": {
        padding: 7,
        "& > span": {
          textTransform: "uppercase",
          fontWeight: 500,
          fontSize: "0.9375rem",
          minWidth: 100
        }
      }
    }
  },
  dateSlectors: {
    "& > *": {
      marginRight: 15
    }
  },
  openedFilters: {
    backgroundColor: "#b9b9b9"
  },
  desktopFilters: {
    width: "calc(100% / 4)"
  },
  mobileMenuContainer: {
    marginBottom: 40,
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      display: "flex",
      marginBottom: 0,
      backgroundColor: "#c4c4c430"
    }
  },
  mobileMenuContent: {
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      display: "flex",
      alignItems: "center",
      paddingBottom: 6,
      borderBottom: "1px solid #E5E5E5",
      paddingLeft: 20,
      position: "fixed",
      justifyContent: "flex-end",
      zIndex: 5,
      transform: "translate3d(0,0,0)",
      backgroundColor: "#fafafa",
      width: "100%",
      paddingRight: 10
    }
  },
  mobileTitle: {
    paddingTop: 70,
    marginLeft: 54,
    paddingRight: 15,
    paddingLeft: 20,
    borderLeft: "1px solid #E5E5E5"
  },
  mobileMenuFilters: {
    position: "absolute",
    backgroundColor: "#fafafa",
    display: "flex",
    flexFlow: "inherit",
    justifyContent: "space-between",
    borderBottom: "1px solid #E5E5E5",
    paddingTop: 55,
    paddingLeft: 20,
    paddingRight: 25,
    paddingBottom: 30,
    width: "100%",
    zIndex: 4
  },
  mobileFilterButtons: { marginBottom: 5, marginTop: 10 },
  mobileFilterInputs: { marginTop: 20 }
});

const viewSelector = [
  { id: "week", name: "Semana" },
  { id: "month", name: "Mes" }
];

const localeMap = {
  es: esLocale
};

const isMonth = currentCalendarView => currentCalendarView === "month";

const Header = ({
  classes,
  onTodayClick,
  onNextWeekClick,
  onPreviousWeekClick,
  t,
  selectedDate,
  handleDateChange,
  name,
  onPdfClick,
  pdfModalComponent,
  setShowModalFalse,
  id,
  startDate,
  endDate,
  checkedClubs,
  clubs,
  teams,
  onClubHandleChange,
  selectableTeams,
  checkedTeams,
  teamFilter,
  onViewSelectorChange,
  checkedDays,
  checkedTypes,
  onTypeHandleChange,
  eventTypes,
  onDayHandleChange,
  daysNames,
  displayableDaysIndex,
  calendarView,
  onFilterSectionOpen,
  isFilterSectionOpen
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const toggleMenu = () => {
    const display = openMobileMenu ? "initial" : "none";
    document.getElementById("compactNav").style.display = display;
    setOpenMobileMenu(!openMobileMenu);
  };

  const getPDFModalComponent = props => (
    <PdfModal
      setShowModal={setShowModalFalse}
      id={id}
      startDate={startDate}
      endDate={endDate}
      checkedTypes={checkedTypes}
      checkedClubs={checkedClubs}
      checkedTeams={checkedTeams}
      displayableDaysIndex={displayableDaysIndex}
      {...props}
    />
  );

  return (
    <Grid id="menuContainer" container className={classes.mobileMenuContainer}>
      {getMediaQuery(mediaQueryBreakpoints.DESKTOP) ? (
        <Grid
          container
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid>
            <Typography variant="h4" gutterBottom component="h2">
              {name}
            </Typography>
          </Grid>

          <Grid
            item
            style={{
              alignItems: " center",
              display: "inline-flex",
              justifyContent: "flex-end"
            }}
            className={classes.dateSlectors}
          >
            <Button size="large" variant="outlined" onClick={onTodayClick}>
              {t("Hoy")}
            </Button>

            <FormControl
              size="large"
              variant="outlined"
              className="MuiButton-root-269"
              style={{ fontWeight: 500 }}
            >
              <Select
                value={calendarView}
                onChange={onViewSelectorChange}
                input={
                  <OutlinedInput
                    className={classes.selectMenu}
                    labelWidth={0}
                    id="MuiButton-root-269"
                  />
                }
              >
                {viewSelector.map(selector => (
                  <MenuItem key={selector.name} value={selector.id}>
                    <ListItemText primary={t(selector.name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={localeMap["es"]}
            >
              <DatePicker
                views={["year", "month"]}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
            <IconButton
              aria-label="Anterior"
              onClick={onPreviousWeekClick}
              style={{ margin: 0 }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton aria-label="Siguiente" onClick={onNextWeekClick}>
              <ChevronRight />
            </IconButton>

            <Fab
              className={"MuiIconButton-root-55"}
              onClick={onPdfClick}
              style={{ boxShadow: "none" }}
            >
              <PictureAsPdfOutlined />
            </Fab>
            {pdfModalComponent && getPDFModalComponent()}
            {!isMonth(calendarView) && (
              <Fab
                onClick={onFilterSectionOpen}
                style={{ boxShadow: "none" }}
                className={`${
                  isFilterSectionOpen ? classes.openedFilters : ""
                } MuiIconButton-root-55`}
              >
                <FilterList />
              </Fab>
            )}
            {isMonth(calendarView) && <div style={{ marginRight: 59 }} />}
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {!pdfModalComponent && (
            <Grid
              className={classes.mobileMenuContent}
              style={
                openMobileMenu
                  ? { borderColor: "#fafafa" }
                  : {
                      borderLeft: "1px solid #E5E5E5",
                      marginLeft: 54,
                      width: "calc(100% - 54px)"
                    }
              }
            >
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap["es"]}
              >
                <DatePicker
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                aria-label="Anterior"
                onClick={onPreviousWeekClick}
                style={{ margin: 0 }}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton aria-label="Siguiente" onClick={onNextWeekClick}>
                <ChevronRight />
              </IconButton>
              <IconButton aria-label="OpenClose" onClick={toggleMenu}>
                {openMobileMenu ? <Close /> : <MoreVert />}
              </IconButton>
            </Grid>
          )}
          {openMobileMenu && (
            <Grid className={classes.mobileMenuFilters}>
              <Button
                size="large"
                variant="outlined"
                onClick={onTodayClick}
                className={classes.mobileFilterButtons}
              >
                {t("Hoy")}
              </Button>

              <FormControl
                size="large"
                variant="outlined"
                className={`MuiButton-root-269 ${classes.mobileFilterButtons}`}
                style={{ fontWeight: 500 }}
              >
                <Select
                  value={calendarView}
                  onChange={onViewSelectorChange}
                  input={
                    <OutlinedInput
                      className={classes.selectMenu}
                      labelWidth={0}
                      id="MuiButton-root-269"
                    />
                  }
                >
                  {viewSelector.map(selector => (
                    <MenuItem key={selector.name} value={selector.id}>
                      <ListItemText primary={t(selector.name)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {!isMonth(calendarView) && (
                <React.Fragment>
                  <FormControl
                    className={`${classes.formControl} ${classes.mobileFilterInputs}`}
                    fullWidth
                  >
                    <InputLabel>{t("Tipo de evento")}</InputLabel>
                    <Select
                      multiple
                      value={checkedTypes}
                      onChange={onTypeHandleChange}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected =>
                        selected.map(({ translate }) => translate).join(", ")
                      }
                      style={{ width: "100%" }}
                    >
                      <MenuItem disabled value="">
                        <em>{t("Tipo de evento")}</em>
                      </MenuItem>
                      {eventTypes.map(type => (
                        <MenuItem key={type.translate} value={type}>
                          <Checkbox
                            checked={checkedTypes.some(
                              ({ translate }) => translate === type.translate
                            )}
                          />
                          <ListItemText primary={type.translate} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={`${classes.formControl} ${classes.mobileFilterInputs}`}
                    fullWidth
                  >
                    <InputLabel>{t("Entidades")}</InputLabel>
                    <Select
                      multiple
                      value={checkedClubs}
                      onChange={onClubHandleChange}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected =>
                        clubs
                          .map(club =>
                            selected.indexOf(club.id) > -1 ? club.name : ""
                          )
                          .filter(sel => sel)
                          .join(", ")
                      }
                      style={{ width: "100%" }}
                    >
                      <MenuItem disabled value="">
                        <em>{t("Entidades")}</em>
                      </MenuItem>
                      {clubs.map(club => (
                        <MenuItem key={club.name} value={club.id}>
                          <Checkbox
                            checked={checkedClubs.indexOf(club.id) > -1}
                          />
                          <ListItemText
                            primary={club.name}
                            primaryTypographyProps={{ color: "inherit" }}
                            style={{
                              background: club.color,
                              color: pickTextColorBasedOnBgColorSimple(
                                club.color,
                                "#FFF",
                                "#000"
                              )
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={`${classes.formControl} ${classes.mobileFilterInputs}`}
                    fullWidth
                  >
                    <InputLabel>{t("Equipos")}</InputLabel>
                    <Select
                      multiple
                      value={checkedTeams}
                      onChange={teamFilter}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected =>
                        teams
                          .map(team =>
                            selected.indexOf(team.id) > -1 ? team.name : ""
                          )
                          .filter(sel => sel)
                          .join(", ")
                      }
                      style={{ width: "100%" }}
                    >
                      <MenuItem disabled value="">
                        <em>{t("Equipos")}</em>
                      </MenuItem>
                      {selectableTeams.map(team => {
                        return (
                          <MenuItem key={team.name} value={team.id}>
                            <Checkbox
                              checked={checkedTeams.indexOf(team.id) > -1}
                            />
                            <ListItemText
                              primary={team.name}
                              primaryTypographyProps={{ color: "inherit" }}
                              style={{
                                background: team.color,
                                color: pickTextColorBasedOnBgColorSimple(
                                  team.color,
                                  "#FFF",
                                  "#000"
                                )
                              }}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Fab
                    className={`MuiIconButton-root-55 ${classes.mobileFilterInputs}`}
                    onClick={onPdfClick}
                    style={{ boxShadow: "none" }}
                  >
                    <PictureAsPdfOutlined />
                  </Fab>
                  {pdfModalComponent &&
                    getPDFModalComponent({ isMobile: true })}
                </React.Fragment>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.mobileTitle}
            style={openMobileMenu ? { borderTop: "1px solid #E5E5E5" } : {}}
          >
            <Typography
              style={{ fontSize: "1.8rem" }}
              variant="h4"
              gutterBottom
              component="h2"
            >
              {name}
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      {!isMonth(calendarView) && isFilterSectionOpen && (
        <Grid container item xs={12} style={{ maxWidth: 1200 }}>
          <Grid
            container
            item
            className={classes.desktopFilters}
            style={{ paddingRight: 20 }}
          >
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Tipo de evento")}</InputLabel>
              <Select
                multiple
                value={checkedTypes}
                onChange={onTypeHandleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected =>
                  selected.map(({ translate }) => translate).join(", ")
                }
                style={{ width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>{t("Tipo de evento")}</em>
                </MenuItem>
                {eventTypes.map(type => (
                  <MenuItem key={type.translate} value={type}>
                    <Checkbox
                      checked={checkedTypes.some(
                        ({ translate }) => translate === type.translate
                      )}
                    />
                    <ListItemText primary={type.translate} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            item
            className={classes.desktopFilters}
            style={{ paddingRight: 20 }}
          >
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Entidades")}</InputLabel>
              <Select
                multiple
                value={checkedClubs}
                onChange={onClubHandleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected =>
                  clubs
                    .map(club =>
                      selected.indexOf(club.id) > -1 ? club.name : ""
                    )
                    .filter(sel => sel)
                    .join(", ")
                }
                style={{ width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>{t("Entidades")}</em>
                </MenuItem>
                {clubs.map(club => (
                  <MenuItem key={club.name} value={club.id}>
                    <Checkbox checked={checkedClubs.indexOf(club.id) > -1} />
                    <ListItemText
                      primary={club.name}
                      primaryTypographyProps={{ color: "inherit" }}
                      style={{
                        background: club.color,
                        color: pickTextColorBasedOnBgColorSimple(
                          club.color,
                          "#FFF",
                          "#000"
                        )
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            item
            className={classes.desktopFilters}
            style={{ paddingRight: 20 }}
          >
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Equipos")}</InputLabel>
              <Select
                multiple
                value={checkedTeams}
                onChange={teamFilter}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected =>
                  teams
                    .map(team =>
                      selected.indexOf(team.id) > -1 ? team.name : ""
                    )
                    .filter(sel => sel)
                    .join(", ")
                }
                style={{ width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>{t("Equipos")}</em>
                </MenuItem>
                {selectableTeams.map(team => {
                  return (
                    <MenuItem key={team.name} value={team.id}>
                      <Checkbox checked={checkedTeams.indexOf(team.id) > -1} />
                      <ListItemText
                        primary={team.name}
                        primaryTypographyProps={{ color: "inherit" }}
                        style={{
                          background: team.color,
                          color: pickTextColorBasedOnBgColorSimple(
                            team.color,
                            "#FFF",
                            "#000"
                          )
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            className={classes.desktopFilters}
            style={{ paddingRight: 20 }}
          >
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Días de la semana")}</InputLabel>
              <Select
                multiple
                value={checkedDays}
                onChange={onDayHandleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => {
                  return daysNames
                    .map(dayName => dayName.translate)
                    .filter(translate => selected.indexOf(translate) !== -1)
                    .join(", ");
                }}
                style={{ width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>{t("Días de la semana")}</em>
                </MenuItem>
                {daysNames.map(dayName => (
                  <MenuItem key={dayName.translate} value={dayName.translate}>
                    <Checkbox
                      checked={checkedDays.indexOf(dayName.translate) > -1}
                    />
                    <ListItemText primary={dayName.translate} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(Header));
