import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  modalContainer: {
    position: "fixed",
    zIndex: 1250,
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    width: "100%",
    height: "100%",
    padding: "20px",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },

  modalContent: {
    backgroundColor: "#fefefe",
    margin: "auto",
    padding: "20px",
    border: "1px solid #888",
    borderRadius: "27px"
  }
});

class Modal extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  render() {
    const { classes, children, onModalContainerClick } = this.props;

    return (
      <div className={classes.modalContainer} onClick={onModalContainerClick}>
        <div
          className={classes.modalContent}
          onClick={event => event.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(Modal));
