import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Page from "components/Page";
import TableBuilder from "components/TableBuilder";
import request from "utils/request";
import { updateLoopbackFilter } from "../utils/loopback";
import { withTranslation } from "react-i18next";
import AddButton from "../ui/AddButton";

const defaultFilter = {
  order: "name ASC",
  offset: 0,
  limit: 10
};

class ClubsDetails extends React.Component {
  filter = { ...defaultFilter };
  state = {
    club: {},
    teams: [],
    count: 0
  };

  headers = [
    {
      title: "",
      property: "color",
      format: color => {
        return (
          <div
            style={{
              backgroundColor: color,
              borderRadius: "50%",
              width: 20,
              height: 20
            }}
          />
        );
      }
    },
    { title: "Nombre", property: "name", isSortable: true, isSearchable: true },
    { title: "Jugadores", property: "players" },
    {
      title: "Entrenador/a",
      property: "coach",
      isSortable: true,
      isSearchable: true,
      format: (value, { coachName, coachEmail, coachPhone }) => (
        <React.Fragment>
          <div xs={12}>{coachName}</div>
          <div xs={12}>{coachEmail}</div>
          <div xs={12}>{coachPhone}</div>
        </React.Fragment>
      )
    },
    {
      title: "Acciones",
      format: (value, item) => (
        <IconButton
          aria-label="Edit"
          onClick={event => {
            event.stopPropagation();
            this.props.history.push(
              `/clubes/${this.state.id}/equipos/${item.id}/editar`
            );
          }}
        >
          <EditIcon />
        </IconButton>
      )
    }
  ];

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id)
      this.setState({ id }, () => {
        this.getClub();
        this.getTeams();
      });
  }

  getClub = () => {
    const { id } = this.state;
    this.getClubRequest = request(`Clubs/${id}`);

    this.getClubRequest.promise.then(club => this.setState({ club }));
  };

  getTeams = (filter = defaultFilter) => {
    if (filter.where) {
      filter.where = {
        and: [{ clubId: this.state.id }, { or: filter.where.or }]
      };
    } else {
      filter.where = { clubId: this.state.id };
    }

    this.teamsRequest = request(
      `Teams?filter=${encodeURI(JSON.stringify(filter))}`
    );
    this.teamsRequest.promise.then(teams => this.setState({ teams }));
    this.teamsRequestCount = request(
      `Teams/count?where=${encodeURI(JSON.stringify(filter.where))}`
    );
    this.teamsRequestCount.promise.then(({ count }) =>
      this.setState({ count })
    );
  };

  updateFilter = ({ order = defaultFilter.order, search, offset }) => {
    this.filter = updateLoopbackFilter({
      filter: this.filter,
      headers: this.headers,
      search,
      order,
      offset
    });
    this.getTeams(this.filter);
  };

  render() {
    const { t } = this.props;
    const { club, teams, count } = this.state;

    return (
      <Page>
        <Grid container>
          <Grid container item xs={12}>
            <Typography
              style={{ marginBottom: 0 }}
              variant="h4"
              gutterBottom
              component="h2"
            >
              {club.name}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  backgroundColor: club.color,
                  borderRadius: "50%",
                  width: "2em",
                  height: "2em"
                }}
              />
            </div>
            <IconButton
              aria-label="Edit"
              onClick={event => {
                event.stopPropagation();
                this.props.history.push(`/clubes/${this.state.id}/editar`);
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{club.zipCode}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.5vw" }}>
            <Typography variant="h6" style={{ marginBottom: "1vw" }}>
              {t("Contacto 1")}
            </Typography>
            <Typography variant="subtitle2">{club.contactName1}</Typography>
            <Typography variant="subtitle2">{club.contactEmail1}</Typography>
            <Typography variant="subtitle2">{club.contactPhone1}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.5vw" }}>
            <Typography variant="h6" style={{ marginBottom: "1vw" }}>
              {t("Contacto 2")}
            </Typography>
            <Typography variant="subtitle2">{club.contactName2}</Typography>
            <Typography variant="subtitle2">{club.contactEmail2}</Typography>
            <Typography variant="subtitle2">{club.contactPhone2}</Typography>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 50 }} item xs={12}>
          <AddButton
            {...this.props}
            onClickPath={`/clubes/${this.props.match.params.id}/equipos/crear`}
            page={t("Equipos")}
            buttonStyles={{ margin: 0 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              component="h3"
              style={{ margin: 0 }}
            >
              {t("Equipos")}
            </Typography>
          </AddButton>
        </Grid>
        <Grid item xs={12}>
          <div>
            <TableBuilder
              headers={this.headers}
              items={teams}
              count={count}
              defaultOrder={defaultFilter.order}
              updateFilter={this.updateFilter}
            />
          </div>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(ClubsDetails);
