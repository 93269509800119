import React from "react";
import Button from "@material-ui/core/Button";
import { Close } from "@material-ui/icons";

export const getToastContent = ({
  method = "undo",
  undo: undoButton = () => {},
  close: closeButton = () => {},
  t
}) => {
  const action = key => (
    <React.Fragment>
      <Button
        onClick={() => {
          undoButton();
          closeButton(key);
        }}
        style={{ color: "#ebdb34" }}
      >
        {t("DESHACER")}
      </Button>
      <Button style={{ color: "white" }} onClick={() => closeButton(key)}>
        <Close />
      </Button>
    </React.Fragment>
  );

  const text = {
    create: "Evento creado correctamente",
    update: "Evento actualizado correctamente",
    delete: "Evento eliminado correctamente"
  };

  return {
    text: t(text[method]),
    format: {
      autoHideDuration: 18000,
      action
    }
  };
};
