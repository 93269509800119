export default function isDataValidated(event) {
  let valid = true;
  const errors = [];

  if (event.divisions.length <= 0 || typeof event.divisions === "undefined") {
    valid = false;
    errors.push({ field: "divisionsRequiredError" });
  }

  if (
    typeof event.selectedClub === "undefined" ||
    event.selectedClub === null
  ) {
    valid = false;
    errors.push({ field: "selectedClubRequiredError" });
  }

  if (
    event.selectedClub &&
    event.selectedClub.id &&
    (typeof event.selectedTeam === "undefined" || event.selectedTeam === null)
  ) {
    valid = false;
    errors.push({ field: "selectedTeamRequiredError" });
  }

  if (
    typeof event.selectedVisitorClub === "undefined" ||
    event.selectedVisitorClub === null
  ) {
    valid = false;
    errors.push({ field: "selectedVisitorClubRequiredError" });
  }

  if (
    event.selectedVisitorClub &&
    event.selectedVisitorClub.id &&
    (typeof event.selectedVisitorTeam === "undefined" ||
      event.selectedVisitorTeam === null)
  ) {
    valid = false;
    errors.push({ field: "selectedVisitorTeamRequiredError" });
  }

  if (event.category === "") {
    valid = false;
    errors.push({ field: "categoryRequiredError" });
  }

  return { valid, errors };
}
