import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import Input from "./Input";
import { withTranslation } from "react-i18next";
import { responsiveWrapper } from "./ResponsiveWrapper";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
};

function TableBuilder({
  classes,
  headers,
  defaultOrder = "",
  items,
  count = 0,
  onCellClick,
  t,
  updateFilter = () => {},
  isDesktop
}) {
  const rowsPerPage = 10;
  const [property, sort] = defaultOrder.split(" ");
  const [order, setOrder] = useState({
    property: property || headers[0].property,
    sort: sort || "ASC"
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  const toggleSort = () => (order.sort === "ASC" ? "DESC" : "ASC");
  const handleChangePage = (event, nextPage) => {
    setPage(nextPage);
    buildFilter({ nextPage });
  };

  const onTableCellClick = ({ property, isSortable }) => {
    if (isSortable) {
      const nextOrder = {
        property,
        sort: toggleSort()
      };
      setOrder(nextOrder);
      buildFilter({
        nextOrder
      });
    }
  };

  const buildFilter = ({
    nextSearch = search,
    nextOrder = order,
    nextPage = page
  }) => {
    updateFilter({
      order: Object.values(nextOrder).join(" "),
      search: nextSearch,
      offset: nextPage * rowsPerPage
    });
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Input
          inputProps={{ autoFocus: isDesktop }}
          placeholder={t("busca...")}
          debounce={500}
          onChange={e => {
            setSearch(e.target.value);
            buildFilter({
              nextSearch: e.target.value
            });
          }}
        />
      </Toolbar>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map(({ title, property, isSortable }) => (
              <TableCell
                key={title}
                onClick={() => onTableCellClick({ property, isSortable })}
              >
                <div
                  style={{
                    display: "flex",
                    cursor: isSortable ? "pointer" : "unset",
                    alignItems: "center"
                  }}
                >
                  {t(title)}
                  {property === order.property && (
                    <ChevronLeft
                      style={{
                        transform: `rotate(${
                          order.sort === "ASC" ? 270 : 90
                        }deg)`
                      }}
                    />
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.id}>{renderItem(item)}</TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={rowsPerPage}
        component="div"
        count={count}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onChangePage={handleChangePage}
      />
    </Paper>
  );

  function renderItem(item) {
    return headers.map((header, index) => {
      return (
        <TableCell
          key={index}
          component="th"
          scope="row"
          style={{
            ...(typeof onCellClick === "function" ? { cursor: "pointer" } : {})
          }}
          onClick={() => typeof onCellClick === "function" && onCellClick(item)}
        >
          {header.format
            ? header.format(item[header.property], item)
            : item[header.property]}
        </TableCell>
      );
    });
  }
}

TableBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
};

export default responsiveWrapper(
  withTranslation()(withStyles(styles)(TableBuilder))
);
