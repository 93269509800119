import React from "react";
import Typography from "@material-ui/core/Typography";
import Page from "components/Page";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TableBuilder from "../components/TableBuilder";
import request from "utils/request";
import { updateLoopbackFilter } from "../utils/loopback";
import { withTranslation } from "react-i18next";
import AddButton from "../ui/AddButton";

const defaultFilter = {
  order: "name ASC",
  offset: 0,
  limit: 10,
  where: {}
};

class Modalities extends React.Component {
  filter = { ...defaultFilter };
  state = {
    modalities: [],
    count: 0
  };

  headers = [
    { title: "Nombre", property: "name", isSearchable: true, isSortable: true },
    {
      title: "Acciones",
      format: (value, item) => (
        <IconButton
          aria-label="Edit"
          onClick={event => {
            event.stopPropagation();
            this.props.history.push(`/modalidades/${item.id}/editar`);
          }}
        >
          <EditIcon />
        </IconButton>
      )
    }
  ];

  componentDidMount() {
    this.getModalities();
  }

  getModalities = (filter = defaultFilter) => {
    this.modalitiesRequest = request(
      `Modalities?filter=${encodeURI(JSON.stringify(filter))}`
    );
    this.modalitiesRequest.promise.then(modalities =>
      this.setState({ modalities })
    );

    this.modalitiesRequestCount = request(
      `Modalities/count?where=${encodeURI(JSON.stringify(filter.where))}`
    );
    this.modalitiesRequestCount.promise.then(({ count }) =>
      this.setState({ count })
    );
  };

  updateFilter = ({ search, order = defaultFilter.order, offset = 0 }) => {
    this.filter = updateLoopbackFilter({
      filter: this.filter,
      headers: this.headers,
      search,
      order,
      offset
    });
    this.getModalities(this.filter);
  };

  render() {
    const { t } = this.props;
    const { modalities, count } = this.state;

    return (
      <Page>
        <AddButton
          {...this.props}
          onClickPath="/modalidades/crear"
          page={t("Modalidades")}
          buttonStyles={{ margin: 0 }}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{ margin: 0 }}
          >
            {t("Modalidades")}
          </Typography>
        </AddButton>
        <div>
          <TableBuilder
            headers={this.headers}
            items={modalities}
            count={count}
            defaultOrder={defaultFilter.order}
            updateFilter={this.updateFilter}
          />
        </div>
      </Page>
    );
  }
}

export default withTranslation()(Modalities);
