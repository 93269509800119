import { DateTime } from "luxon";

export const formatDate = (date, format) =>
  DateTime.fromISO(date).toFormat(format);

export const addQuarterHour = currentHour => {
  const hour = formatDate(currentHour, "HH");
  const minutes = formatDate(currentHour, "mm");

  switch (minutes) {
    case "45":
      return plusHour(hour);

    case "30":
      return hour + ":45";

    case "15":
      return hour + ":30";

    case "00":
      return hour + ":15";
    default:
      return;
  }
};

export const plusHour = currentHour => {
  const plusOneHour = parseInt(currentHour) + 1;
  return plusOneHour < 10 ? `0${plusOneHour}:00` : `${plusOneHour}:00`;
};
