import React from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  Input,
  InputLabel,
  IconButton,
  Icon
} from "@material-ui/core";
import { UnfoldMore, Delete as DeleteIcon } from "@material-ui/icons/";
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Page from "components/Page";
import request from "utils/request";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  fab: {
    margin: theme.spacing(1)
  },
  tableContainer: {
    height: 320
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1) * 3
  }
});
class FacilitesUpsert extends React.Component {
  state = {
    editing: false,
    name: "",
    notes: "",
    divisions: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.setState({ id }, this.getFacility);
  }

  getFacility = () => {
    const { id } = this.state;
    this.getFacilityRequest = request(
      `Facilities/${id}?filter=${encodeURI(
        JSON.stringify({
          include: { relation: "divisions", scope: { order: "rank" } }
        })
      )}`
    );

    this.getFacilityRequest.promise.then(facility =>
      this.setState({ ...facility })
    );
  };

  onSubmit = event => {
    event.preventDefault();

    this.upsertFacilityRequest = request("Facilities", {
      method: "PATCH",
      body: JSON.stringify({
        ...this.state,
        divisions: this.state.divisions.map(
          ({ id, name, shortName }, index) => ({
            id,
            name,
            shortName,
            rank: index
          })
        )
      })
    });

    this.upsertFacilityRequest.promise.then(() =>
      this.props.history.push("/instalaciones")
    );
  };

  onInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  addDivision = () => {
    this.setState({ divisions: [...this.state.divisions, { name: "" }] });
  };

  onDivisionInputChange = ({ target, index }) => {
    const divisions = [...this.state.divisions];
    divisions[index][target.name] = target.value;
    this.setState({ divisions });
  };

  removeDivision = index => {
    const divisions = this.state.divisions.filter(
      (division, idx) => index !== idx
    );
    this.setState({ divisions });
  };

  reorderDivisions = ({ source, destination }) => {
    const divisions = [...this.state.divisions].filter(
      (division, index) => index !== source.index
    );
    divisions.splice(destination.index, 0, this.state.divisions[source.index]);

    this.setState({ divisions });
  };

  render() {
    const { classes, t } = this.props;
    const { id, name, notes } = this.state;

    return (
      <Page>
        <Typography variant="h4" gutterBottom component="h1">
          {id ? "Editar" : "Crear"} {t("Instalación")}
        </Typography>
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12} md={6}>
            <form onSubmit={this.onSubmit} className={this.props.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name">{t("Nombre")}</InputLabel>
                <Input
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{t("Notas")}</InputLabel>
                <Input
                  id="notes"
                  name="notes"
                  autoComplete="notes"
                  multiline={true}
                  rows={10}
                  value={notes}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Grid container style={{ marginTop: 50, marginBottom: 50 }}>
                <Grid item xs={12} md={6}>
                  <Typography
                    style={{ marginBottom: 0 }}
                    variant="h5"
                    gutterBottom
                    component="h2"
                  >
                    {t("Divisiones")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    style={{ marginTop: 0 }}
                    variant="contained"
                    color="default"
                    className={this.props.classes.submit}
                    onClick={this.addDivision}
                  >
                    {t("Añadir División")}
                  </Button>
                </Grid>
              </Grid>
              {this.renderDivisions()}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
              >
                {id ? t("Actualizar") : "Crear"} {t("Instalación")}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Page>
    );
  }

  renderDivisions = () => {
    const { divisions } = this.state;
    return (
      <DragDropContext onDragEnd={this.reorderDivisions}>
        <Droppable droppableId="divisions">
          {provider => (
            <div ref={provider.innerRef} {...provider.droppableProps}>
              {divisions.map(this.renderDivision)}
              {provider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderDivision = (division, index) => {
    const t = this.props.t;
    return (
      <Draggable draggableId={index.toString()} index={index} key={index}>
        {(provider, snapshot) => (
          <div
            {...provider.draggableProps}
            ref={provider.innerRef}
            isDragging={snapshot.isDragging}
          >
            <Grid container>
              <Grid
                item
                xs={7}
                style={{ flexDirection: "row", background: "#FAFAFA" }}
              >
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="name">{t("Nombre")}</InputLabel>
                  <Input
                    id={"division" + index}
                    name="name"
                    value={division.name}
                    autoComplete="false"
                    onChange={event =>
                      this.onDivisionInputChange({
                        target: event.target,
                        index
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  flexDirection: "row",
                  background: "#FAFAFA",
                  paddingLeft: "10px"
                }}
              >
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="name">
                    {t("Nombre")} {t("Corto")}
                  </InputLabel>
                  <Input
                    id={"division" + index}
                    name="shortName"
                    value={division.shortName}
                    autoComplete="false"
                    inputProps={{ maxLength: 4 }}
                    onChange={event =>
                      this.onDivisionInputChange({
                        target: event.target,
                        index
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  background: "#FAFAFA",
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flex: "0 0 auto"
                }}
              >
                <IconButton
                  onClick={() => this.removeDivision(index)}
                  aria-label="Edit"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  background: "#FAFAFA",
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <Icon aria-label="Change Position">
                  <div {...provider.dragHandleProps}>
                    <UnfoldMore />
                  </div>
                </Icon>
              </Grid>
            </Grid>
          </div>
        )}
      </Draggable>
    );
  };
}

export default withTranslation()(
  withStyles(styles)(withRouter(FacilitesUpsert))
);
