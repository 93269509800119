import { DateTime } from "luxon";
import { groupItemsByProperty } from "./reducers";

const startHour = DateTime.fromObject({ hour: 6, minute: 0 });
const endHour = DateTime.fromObject({ hour: 23, minute: 0 }).toFormat("HH:mm");
let hours = [];
export const cellHeight = 18;

export const weekDays = [
  { translate: "Lunes", day: "" },
  { translate: "Martes", day: "" },
  { translate: "Miércoles", day: "" },
  { translate: "Jueves", day: "" },
  { translate: "Viernes", day: "" },
  { translate: "Sábado", day: "" },
  { translate: "Domingo", day: "" }
];

export const setDate = selectedDate =>
  weekDays.map((weekDay, index) => {
    const day = selectedDate.startOf("week").plus({ days: index });
    return {
      ...weekDay,
      day: day.toFormat("dd/MM/yyyy"),
      dateTime: day,
      onlyDayNumber: day.toFormat("d")
    };
  });

export const formatTimeDivision = () => {
  if (hours.length === 0) {
    for (let i = 0; hours[hours.length - 1] !== endHour; i += 30) {
      hours.push(startHour.plus({ minutes: i }).toFormat("HH:mm"));
    }
  }
  return hours;
};

export const shouldRequestEvents = (currentView, nextView) =>
  currentView === "month" && nextView === "week" ? false : true;

export const isToday = date => {
  const today = DateTime.local().toFormat("dd/MM/yyyy");
  return today === date;
};

export const pickTextColorBasedOnBgColorSimple = (
  bgColor,
  lightColor,
  darkColor
) => {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const haveLength = elements => elements.length > 0;

export const haveElements = elements => (haveLength(elements) ? elements : []);

export const filterEvents = ({
  events,
  checkedTypesNames = [],
  checkedClubs = [],
  checkedTeams = []
}) => {
  return events
    .filter(event =>
      checkedTypesNames.length !== 0
        ? checkedTypesNames.indexOf(event.type) > -1
        : true
    )
    .filter(event =>
      checkedClubs.length !== 0
        ? event.teamId !== null && checkedClubs.indexOf(event.team.clubId) > -1
        : true
    )
    .filter(event =>
      checkedTeams.length !== 0
        ? event.teamId !== null && checkedTeams.indexOf(event.teamId) > -1
        : true
    );
};

export const getFirstWeekdaysOfMonth = (selectedDate, weeks) =>
  selectedDate
    .startOf("month")
    .plus({ weeks })
    .startOf("week");

export const getNumberWeeksOfMonth = selectedDate => {
  const numDaysInMonth = selectedDate.daysInMonth;
  const numBlankDaysStartOfMonth = selectedDate.startOf("month").weekday - 1;
  const numBlankDaysEndOfMonth = 7 - selectedDate.endOf("month").weekday;

  return (
    (numBlankDaysStartOfMonth + numDaysInMonth + numBlankDaysEndOfMonth) / 7
  );
};

export const hasEvents = ({ eventsDates, currentDay }) =>
  typeof eventsDates[currentDay] !== "undefined";

export const isType = ({ eventsDates, currentDay, type }) => {
  const types = hasEvents({ eventsDates, currentDay })
    ? eventsDates[currentDay]
    : [];

  return types.some(currentType => currentType === type);
};

export const getEventDates = (events, selectedDate) => {
  const currentMonth = currentEvent =>
    parseInt(DateTime.fromISO(currentEvent.startDate).toFormat("M")) ===
    selectedDate.month
      ? [currentEvent]
      : [];

  const eventsInfo = events.reduce((reducer, event) => {
    const eventInfo = {
      startDate: DateTime.fromISO(event.startDate).toFormat("yyyy-MM-dd"),
      type: event.type
    };
    return [...reducer, ...currentMonth(eventInfo)];
  }, []);

  const groupedDates = groupItemsByProperty({
    items: eventsInfo,
    groupBy: "startDate",
    property: "type"
  });

  return groupedDates;
};

export const getWeeks = selectedDate => {
  const currentMonth = selectedDate.month;

  return [...Array(Math.ceil(getNumberWeeksOfMonth(selectedDate)))]
    .map((unused, index) => getFirstWeekdaysOfMonth(selectedDate, index))
    .map(firstDayOfWeek => {
      const days = [...Array(7)].map((unused, index) => {
        const currentDay = firstDayOfWeek.plus({ days: index });
        return currentDay.month === currentMonth
          ? currentDay.toFormat("dd")
          : "";
      });
      return { days };
    });
};

export const isNullOrEmpty = value =>
  !value || value === undefined || value === "" || value.length === 0;
