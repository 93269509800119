import React from "react";
import {
  Grid,
  FormHelperText,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Switch
} from "@material-ui/core";

function RepeatableSwitch({
  onInputChange,
  onDayClick,
  days,
  isRepeatable,
  repetitionEndDate,
  fieldRequiredError,
  t,
  startDate = "",
  wasRepeatable
}) {
  return (
    <>
      <Grid item xs={12}>
        <InputLabel>{t("¿Es repetitivo?")}</InputLabel>
        <Switch
          name="isRepeatable"
          checked={isRepeatable}
          onChange={onInputChange}
          disabled={isRepeatable && repetitionEndDate && wasRepeatable}
        />
      </Grid>
      {isRepeatable &&
        renderRepeatableForm({
          days,
          repetitionEndDate,
          onInputChange,
          onDayClick,
          fieldRequiredError,
          t,
          startDate
        })}
    </>
  );
}

function renderRepeatableForm({
  days,
  repetitionEndDate,
  onInputChange,
  onDayClick,
  fieldRequiredError,
  t,
  startDate = ""
}) {
  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-around",
          border: fieldRequiredError.repeatDaysRequiredError
            ? "solid 1px #F44336"
            : "none"
        }}
      >
        {days.map(({ name, active }, index) => (
          <div
            key={index}
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: active ? "#1967D2" : "#f1f3f4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => onDayClick(index)}
          >
            <Typography style={{ color: active ? "white" : "black" }}>
              {name}
            </Typography>
          </div>
        ))}
      </Grid>
      <Grid item xs={12}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            name="repetitionEndDate"
            type="date"
            label={t("Fecha Fin de Repetición")}
            value={repetitionEndDate}
            defaultValue={startDate}
            InputLabelProps={{
              shrink: true
            }}
            onChange={onInputChange}
            error={
              fieldRequiredError.repetitionEndDateRequiredError ||
              fieldRequiredError.repetitionEndDateInvalidError
            }
          />
          {fieldRequiredError.repetitionEndDateRequiredError && (
            <FormHelperText style={{ color: "#F44336" }}>
              {t("Campo requerido")}
            </FormHelperText>
          )}
          {fieldRequiredError.repetitionEndDateInvalidError && (
            <FormHelperText style={{ color: "#F44336" }}>
              {t("Debe ser posterior a la fecha inicial")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}

export default RepeatableSwitch;
