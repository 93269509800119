import React, { useState } from "react";
import { TextField } from "@material-ui/core";

let debounceFn;

export default React.forwardRef(({ onChange, debounce = 0, ...props }, ref) => {
  const [value, setValue] = useState("");

  function debounceOnChange(event) {
    event.persist();
    const nextValue = event.target.value;
    setValue(nextValue);

    if (debounceFn) {
      clearTimeout(debounceFn);
    }

    if (nextValue !== "") {
      debounceFn = setTimeout(() => onChange(event), debounce);
    } else {
      onChange(event);
    }
  }

  return (
    <TextField
      inputProps={{ autoFocus: true }}
      {...props}
      value={value}
      onChange={debounce ? debounceOnChange : onChange}
      ref={ref}
    />
  );
});
