import isRepeatableEventValidated from "./repeatableEventValidation";

export default function isDataValidated(event) {
  const errors = [
    ...(event.divisions.length <= 0 || typeof event.divisions === "undefined"
      ? [{ field: "divisionsRequiredError" }]
      : []),
    ...(event.freeEventName === ""
      ? [{ field: "freeEventNameRequiredError" }]
      : [])
  ];

  return isRepeatableEventValidated({ event, errors });
}
