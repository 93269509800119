import React from "react";
import Typography from "@material-ui/core/Typography";
import Page from "components/Page";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TableBuilder from "../components/TableBuilder";
import request from "utils/request";
import { updateLoopbackFilter } from "../utils/loopback";
import { withTranslation } from "react-i18next";
import AddButton from "../ui/AddButton";

const defaultFilter = {
  order: "name ASC",
  offset: 0,
  limit: 10,
  include: "divisions",
  where: {}
};

class Facilites extends React.Component {
  filter = { ...defaultFilter };
  state = {
    facilities: [],
    count: 0
  };

  headers = [
    { title: "Nombre", property: "name", isSearchable: true, isSortable: true },
    { title: "Divisiones", property: "divisions", format: item => item.length },
    {
      title: "Acciones",
      format: (value, item) => (
        <IconButton
          aria-label="Edit"
          onClick={event => {
            event.stopPropagation();
            this.props.history.push(`/instalaciones/${item.id}/editar`);
          }}
        >
          <EditIcon />
        </IconButton>
      )
    }
  ];

  componentDidMount() {
    this.getFacilities();
  }

  getFacilities = (filter = defaultFilter) => {
    this.facilitiesRequest = request(
      `Facilities?filter=${encodeURI(JSON.stringify(filter))}`
    );
    this.facilitiesRequest.promise.then(facilities =>
      this.setState({ facilities })
    );

    this.facilitiesRequestCount = request(
      `Facilities/count?where=${encodeURI(JSON.stringify(filter.where))}`
    );
    this.facilitiesRequestCount.promise.then(({ count }) =>
      this.setState({ count })
    );
  };

  updateFilter = ({ search, order = defaultFilter.order, offset = 0 }) => {
    this.filter = updateLoopbackFilter({
      filter: this.filter,
      headers: this.headers,
      search,
      order,
      offset
    });
    this.getFacilities(this.filter);
  };

  render() {
    const { t } = this.props;
    const { facilities, count } = this.state;

    return (
      <Page>
        <AddButton
          {...this.props}
          onClickPath="/instalaciones/crear"
          page={t("Instalaciones")}
          buttonStyles={{ margin: 0 }}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{ margin: 0 }}
          >
            {t("Instalaciones")}
          </Typography>
        </AddButton>
        <div>
          <TableBuilder
            headers={this.headers}
            items={facilities}
            count={count}
            onCellClick={item =>
              this.props.history.push(`/instalaciones/${item.id}`)
            }
            defaultOrder={defaultFilter.order}
            updateFilter={this.updateFilter}
          />
        </div>
      </Page>
    );
  }
}

export default withTranslation()(Facilites);
