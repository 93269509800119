import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { SportsSoccer } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import {
  weekDays,
  isToday,
  getEventDates,
  getWeeks,
  hasEvents,
  isType
} from "../../utils/calendar";
import { mediaQueryBreakpoints } from "../../utils/responsive";

const styles = theme => ({
  mainBody: {
    display: "flex",
    height: "calc(100vh - 215px)",
    overflowY: "auto"
  },
  dayName: {
    fontSize: "12px",
    textAlign: "center",
    textTransform: "uppercase"
  },
  dayNumber: {
    display: "flex",
    fontSize: "17px",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center"
  },
  monthContainer: {
    flexDirection: "column"
  },
  weekNamesContainer: {
    display: "flex",
    minHeight: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e0e0e0",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  weekContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 15
    }
  },
  week: {
    display: "flex",
    flexGrow: 1
  },
  day: {
    flex: 1,
    border: "1px solid #e0e0e0",
    position: "relative",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      border: 0
    }
  },
  nameDay: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  emptyDayEvent: {
    display: "flex",
    fontSize: "17px",
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  dayEvent: {
    color: "#fff",
    background: "#1856a8",
    borderRadius: "50%",
    fontWeight: 500,
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      fontWeight: 400
    }
  },
  emptyDayNumber: {
    display: "flex",
    fontSize: "17px",
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down(mediaQueryBreakpoints.DESKTOP)]: {
      fontWeight: 400
    }
  },
  dayNumberToday: {
    color: "#fff",
    background: "#2986ff",
    fontWeight: 400,
    borderRadius: "50%"
  },
  matchIcon: {
    position: "absolute",
    top: 7,
    right: 10
  }
});

const Days = ({ classes, t }) => (
  <div className={classes.weekNamesContainer}>
    {weekDays.map((dayOfWeek, index) => (
      <Typography
        variant="h5"
        className={`${classes.dayName} ${classes.nameDay}`}
        key={index}
      >
        {t(dayOfWeek.translate).substring(0, 3)}
      </Typography>
    ))}
  </div>
);

const Week = ({ classes, children, onClick }) => (
  <div onClick={onClick} className={classes.week}>
    {children}
  </div>
);

const Day = ({
  classes,
  day,
  currentDate,
  hasEvents = false,
  isMatch = false
}) => (
  <Typography variant="h5" className={`${classes.day} ${classes.dayNumber}`}>
    {isMatch && <SportsSoccer className={`${classes.matchIcon} matchIcon`} />}
    <div
      className={`${classes.emptyDayEvent} ${
        hasEvents ? classes.dayEvent : ""
      }`}
    >
      <div
        className={`${classes.emptyDayNumber} ${
          isToday(day + currentDate) && day !== "" ? classes.dayNumberToday : ""
        }`}
      >
        {day}
      </div>
    </div>
  </Typography>
);

class CalendarMonth extends React.Component {
  render() {
    const { classes, t, selectedDate, goToSelectedWeek, events } = this.props;
    const eventsDates = getEventDates(events, selectedDate);
    const weeks = getWeeks(selectedDate);

    return (
      <React.Fragment>
        <div className={`${classes.mainBody} ${classes.monthContainer}`}>
          <Days classes={classes} t={t} />
          <div className={classes.weekContainer}>
            {weeks.map((week, index) => (
              <Week
                key={index}
                onClick={() =>
                  goToSelectedWeek({
                    day: parseInt(week.days.filter(day => day)[0]),
                    month: selectedDate.get("month"),
                    year: selectedDate.get("year")
                  })
                }
                classes={classes}
              >
                {week.days.map((day, index) => (
                  <Day
                    currentDate={selectedDate.toFormat("/MM/yyyy")}
                    key={index}
                    classes={classes}
                    day={day}
                    hasEvents={hasEvents({
                      eventsDates,
                      currentDay: `${selectedDate.toFormat("yyyy-MM-")}${day}`
                    })}
                    isMatch={isType({
                      eventsDates,
                      currentDay: `${selectedDate.toFormat("yyyy-MM-")}${day}`,
                      type: "match"
                    })}
                  />
                ))}
              </Week>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(CalendarMonth));
