import React from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  Input,
  InputLabel
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Page from "components/Page";
import request from "utils/request";
import { withTranslation } from "react-i18next";
import { Select } from "ui";

const styles = theme => ({
  fab: {
    margin: theme.spacing(1)
  },
  tableContainer: {
    height: 320
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1) * 3
  }
});
class ModalitiesUpsert extends React.Component {
  state = {
    editing: false,
    name: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.setState({ id }, this.getModality);
  }

  getModality = () => {
    const { id } = this.state;
    this.getModalityRequest = request(`Modalities/${id}`);

    this.getModalityRequest.promise.then(modality =>
      this.setState({ ...modality })
    );
  };

  onSubmit = event => {
    event.preventDefault();

    this.upsertModalityRequest = request("Modalities", {
      method: "PATCH",
      body: JSON.stringify({
        ...this.state
      })
    });

    this.upsertModalityRequest.promise.then(() =>
      this.props.history.push("/modalidades")
    );
  };

  onInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  render() {
    const { classes, t } = this.props;
    const { id, name } = this.state;

    return (
      <Page>
        <Typography variant="h4" gutterBottom component="h1">
          {id ? "Editar" : "Crear"} {t("Modalidad")}
        </Typography>
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12} md={6}>
            <form onSubmit={this.onSubmit} className={this.props.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name">{t("Nombre")}</InputLabel>
                <Input
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Select
                options={[{ value: "ASDA", label: "ASDA" }]}
                isMulti
              ></Select>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
              >
                {id ? t("Actualizar") : "Crear"} {t("Modalidad")}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRouter(ModalitiesUpsert))
);
