import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { responsiveWrapper } from "../components/ResponsiveWrapper";

const styles = theme => ({
  addButton: {
    margin: theme.spacing(1),
    marginTop: 20,
    marginRight: 0
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

class AddButton extends React.Component {
  render() {
    const {
      classes,
      t,
      containterStyles = {},
      buttonStyles = {},
      onClickPath = "",
      page = "",
      children,
      isDesktop
    } = this.props;

    return (
      <div
        style={
          isDesktop
            ? {
                paddingBottom: 40,
                paddingTop: 10,
                display: "flex",
                justifyContent: "space-between"
              }
            : { paddingBottom: 25 }
        }
      >
        {children}

        <div
          className={classes.addButtonContainer}
          style={
            isDesktop
              ? containterStyles
              : { marginTop: 40, ...containterStyles }
          }
        >
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${onClickPath}`)}
            style={buttonStyles}
          >
            {`${t("Añadir")} ${page}`}
          </Button>
        </div>
      </div>
    );
  }
}
export default responsiveWrapper(
  withTranslation()(withStyles(styles)(AddButton))
);
