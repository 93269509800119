import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Page from "components/Page";
import request from "utils/request";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  fab: {
    margin: theme.spacing(1)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1) * 3
  }
});

class TeamsUpsert extends React.Component {
  state = {
    club: {},
    id: null,
    name: "",
    players: 0,
    coachName: "",
    coachEmail: "",
    coachPhone: "",
    notes: ""
  };

  componentDidMount() {
    const { id, teamId } = this.props.match.params;

    if (id && teamId) {
      Promise.all([this.getClub(id), this.getTeam(teamId)]).then(
        ([club, team]) => {
          this.setState({ ...team, teamId, club });
        }
      );
    } else if (id) {
      this.getClub(id).then(club => this.setState({ club }));
    }
  }

  getClub = id => {
    this.getClubRequest = request(`Clubs/${id}`);
    return this.getClubRequest.promise;
  };

  getTeam = teamId => {
    this.getTeamRequest = request(`Teams/${teamId}`);
    return this.getTeamRequest.promise;
  };

  onSubmit = event => {
    event.preventDefault();
    this.upsertTeam();
  };

  upsertTeam = () => {
    const { id, club, ...team } = this.state;

    this.upsertTeamRequest = request("Teams", {
      method: "PATCH",
      body: JSON.stringify({ ...team, id, clubId: club.id })
    });

    this.upsertTeamRequest.promise.then(() =>
      this.props.history.push("/clubes/" + club.id)
    );
  };

  onInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  handleChangeComplete = color => {
    this.setState({ color: color.hex });
  };

  render() {
    const { classes, t } = this.props;
    const {
      club,
      id,
      name,
      players,
      coachName,
      coachEmail,
      coachPhone,
      notes
    } = this.state;

    return (
      <Page>
        <Typography variant="h4" gutterBottom component="h1">
          {id ? "Editar" : "Crear"} {t("equipo para la entidad")} {club.name}
        </Typography>
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12} md={6}>
            <form onSubmit={this.onSubmit} className={this.props.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name">{t("Nombre")}</InputLabel>
                <Input
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{t("Jugadores")}</InputLabel>
                <Input
                  id="players"
                  name="players"
                  autoComplete="players"
                  type="number"
                  value={players}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Typography
                style={{ marginTop: 50 }}
                variant="h5"
                gutterBottom
                component="h1"
              >
                Entrenador/a
              </Typography>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">
                  {t("Nombre")} Entrenador/a
                </InputLabel>
                <Input
                  id="coachName"
                  name="coachName"
                  autoComplete="coachName"
                  value={coachName}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">Email Entrenador/a</InputLabel>
                <Input
                  id="coachEmail"
                  name="coachEmail"
                  autoComplete="coachEmail"
                  value={coachEmail}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" style={{ paddingRight: 20 }}>
                <InputLabel htmlFor="name">
                  {t("Teléfono Entrenador/a")}
                </InputLabel>
                <Input
                  id="coachPhone"
                  name="coachPhone"
                  autoComplete="coachPhone"
                  value={coachPhone}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{t("Notas")}</InputLabel>
                <Input
                  id="notes"
                  name="notes"
                  autoComplete="notes"
                  multiline={true}
                  rows={10}
                  value={notes}
                  onChange={this.onInputChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
              >
                {id ? t("Actualizar") : "Crear"} {t("Equipo")}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(TeamsUpsert)));
