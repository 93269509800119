import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Page from "components/Page";
import TableBuilder from "../components/TableBuilder";
import request from "utils/request";
import { updateLoopbackFilter } from "../utils/loopback";
import AddButton from "../ui/AddButton";
import { withTranslation } from "react-i18next";

const defaultFilter = {
  order: "name ASC",
  offset: 0,
  limit: 10,
  include: ["teams", "modality"],
  where: {}
};

class Clubs extends React.Component {
  filter = { ...defaultFilter };
  state = {
    clubs: [],
    count: 0
  };

  headers = [
    {
      title: "",
      property: "color",
      format: color => {
        return (
          <div
            style={{
              backgroundColor: color,
              borderRadius: "50%",
              width: 20,
              height: 20
            }}
          />
        );
      }
    },
    {
      title: "Nombre",
      property: "name",
      isSearchable: true,
      isSortable: true
    },
    { title: "Equipos", property: "teams", format: item => item.length },
    {
      title: "Modalidad Principal",
      property: "modality",
      format: item => (item ? item.name : ""),
      isSearchable: search => ({
        name: {
          like: `%${search}%`
        }
      }),
      isSortable: true
    },
    {
      title: "Código Postal",
      property: "zipCode",
      isSearchable: true,
      isSortable: true
    },
    {
      title: "Contacto 1",
      property: "contactName1",
      isSearchable: true,
      format: (item, { contactEmail1, contactPhone1 }) => {
        return (
          <React.Fragment>
            <div xs={12}>{item}</div>
            <div xs={12}>{contactEmail1}</div>
            <div xs={12}>{contactPhone1}</div>
          </React.Fragment>
        );
      }
    },
    {
      title: "Contacto 2",
      property: "contactName2",
      isSearchable: true,
      format: (item, { contactEmail2, contactPhone2 }) => {
        return (
          <React.Fragment>
            <div xs={12}>{item}</div>
            <div xs={12}>{contactEmail2}</div>
            <div xs={12}>{contactPhone2}</div>
          </React.Fragment>
        );
      }
    },
    {
      title: "Acciones",
      format: (value, item) => (
        <IconButton
          aria-label="Edit"
          onClick={event => {
            event.stopPropagation();
            this.props.history.push(`/clubes/${item.id}/editar`);
          }}
        >
          <EditIcon />
        </IconButton>
      )
    }
  ];

  componentDidMount() {
    this.getClubs();
  }

  getClubs = (filter = defaultFilter) => {
    this.clubsRequest = request(
      `Clubs?filter=${encodeURI(JSON.stringify(filter))}`
    );
    this.clubsRequest.promise.then(clubs => this.setState({ clubs }));
    this.clubsRequestCount = request(
      `Clubs/count?where=${encodeURI(JSON.stringify(filter.where))}`
    );
    this.clubsRequestCount.promise.then(({ count }) =>
      this.setState({ count })
    );
  };

  updateFilter = ({ order = defaultFilter.order, search, offset }) => {
    this.filter = updateLoopbackFilter({
      filter: this.filter,
      headers: this.headers,
      search,
      order,
      offset
    });
    this.getClubs(this.filter);
  };

  render() {
    const { t } = this.props;
    const { clubs, count } = this.state;

    return (
      <Page>
        <AddButton
          {...this.props}
          onClickPath="/clubes/crear"
          page={t("Entidades")}
          buttonStyles={{ margin: 0 }}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{ margin: 0 }}
          >
            {t("Entidades")}
          </Typography>
        </AddButton>

        <div>
          <TableBuilder
            headers={this.headers}
            items={clubs}
            count={count}
            onCellClick={item => this.props.history.push(`/clubes/${item.id}`)}
            defaultOrder={defaultFilter.order}
            updateFilter={this.updateFilter}
          />
        </div>
      </Page>
    );
  }
}

export default withTranslation()(Clubs);
