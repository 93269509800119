export function getWithoutDuplication(reducer, item, property = "") {
  const alreadyInReducer = reducer.some(
    reducerInstance => reducerInstance[property] === item[property]
  );

  return [...reducer, ...(alreadyInReducer ? [] : [item])];
}

export function getItemsWithoutDuplication({ array, prop, sort = false }) {
  const elements = [];

  const filteredElements = array.filter(obj => {
    const elementIndex = elements.indexOf(obj[prop]);
    elements[elementIndex > -1 ? elementIndex : elements.length] = obj[prop];

    return elementIndex === -1;
  });

  return sort
    ? filteredElements.sort((prev, next) => prev[prop] - next[prop])
    : filteredElements;
}

export function getFilteredItems(toReduce, whereToFind, toMatch) {
  const arrayWhereToFind = Array.isArray(whereToFind)
    ? whereToFind
    : [whereToFind];
  return toReduce.reduce((reducer, item) => {
    return [
      ...reducer,
      ...(arrayWhereToFind.findIndex(findInstance =>
        toMatch(item, findInstance)
      ) > -1
        ? [item]
        : [])
    ];
  }, []);
}

export const groupItemsByProperty = ({ items = [], groupBy, property }) =>
  items.reduce((reducer, item) => {
    const currentItem = property ? item[property] : item;
    const reducerArray = reducer[item[groupBy]];
    const collection =
      typeof reducerArray === "undefined"
        ? [currentItem]
        : [
            ...reducerArray,
            ...(reducerArray.some(reducerItem => currentItem === reducerItem)
              ? []
              : [currentItem])
          ];

    return { ...reducer, [item[groupBy]]: [...collection] };
  }, {});

export const deleteObjectProp = (obj, prop) => {
  let { [prop]: omit, ...res } = obj;
  return res;
};
