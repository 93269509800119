import React from "react";
import { DateTime } from "luxon";
import Page from "components/Page";
import request from "utils/request";
import EventDialog from "components/Calendar/Events/EventDialog";
import { getWithoutDuplication, deleteObjectProp } from "../utils/reducers";
import { withTranslation } from "react-i18next";
import CalendarWeek from "components/Calendar/CalendarWeek";
import CalendarMonth from "../components/Calendar/CalendarMonth";
import Header from "../components/Calendar/Header";
import {
  shouldRequestEvents,
  haveElements,
  filterEvents
} from "../utils/calendar";
import { responsiveWrapper } from "../components/ResponsiveWrapper";
import { getToastContent } from "../utils/undoNotifications";
import { withSnackbar } from "notistack";

class FacilitiesDetails extends React.Component {
  state = {
    id: "",
    divisions: [],
    name: "",
    events: [],
    filteredEvents: [],
    filteredTeamEvents: [],
    event: {},
    open: false,
    clubs: [],
    checkedClubs: [],
    checkedDays: [],
    checkedTeams: [],
    teams: [],
    selectableTeams: [],
    checkedTypes: [],
    startDate: DateTime.local().startOf("week"),
    endDate: DateTime.local().endOf("week"),
    pdfModalComponent: false,
    calendarView: "week",
    selectedDate: DateTime.local(),
    isFilterSectionOpen: false,
    swipeableViewsIndex: DateTime.local().weekday - 1,
    areCheckedDaysReset: true
  };

  eventTypes = [
    { name: ["free", "repeatableFree"], translate: this.props.t("Evento") },
    { name: ["match"], translate: this.props.t("Partido") },
    { name: ["train", "repeatableTrain"], translate: this.props.t("Entreno") }
  ];

  daysNames = [
    { name: "monday", translate: this.props.t("Lunes") },
    { name: "tuesday", translate: this.props.t("Martes") },
    { name: "wednesday", translate: this.props.t("Miércoles") },
    { name: "thursday", translate: this.props.t("Jueves") },
    { name: "friday", translate: this.props.t("Viernes") },
    { name: "saturday", translate: this.props.t("Sábado") },
    { name: "sunday", translate: this.props.t("Domingo") }
  ];

  componentDidMount = () => {
    const { id } = this.props.match.params;

    if (document.getElementById("compactNav") !== null) {
      document.getElementById("compactNav").style.width = "55px";
    }

    if (this.props.isDesktop) this.setState({ areCheckedDaysReset: true });

    if (id)
      this.setState({ id }, () =>
        Promise.all([this.getFacility(), this.getEvents()])
      );
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isDesktop &&
      this.props.isDesktop &&
      !this.state.areCheckedDaysReset
    ) {
      this.resetFilters([
        "checkedClubs",
        "checkedTypes",
        "checkedTeams",
        "filteredEvents",
        "filteredTeamEvents",
        "selectableTeams"
      ]);
      this.setState({ areCheckedDaysReset: true });
    }
  }

  getFacility = () => {
    const { id } = this.state;
    this.getFacilityRequest = request(
      `Facilities/${id}?filter=${encodeURI(
        JSON.stringify({
          include: { relation: "divisions", scope: { order: "rank" } }
        })
      )}`
    );

    this.getFacilityRequest.promise.then(facility =>
      this.setState({ ...facility })
    );
  };

  getClubsAndTeams = () => {
    const clubs = this.state.events
      .filter(event => event.team)
      .map(event => event.team.club)
      .reduce(
        (events, event) => getWithoutDuplication(events, event, "id"),
        []
      );

    const teams = this.state.events
      .filter(event => event.team)
      .map(event => event.team)
      .reduce(
        (events, event) => getWithoutDuplication(events, event, "id"),
        []
      );

    this.setState({ clubs, teams });
  };

  getSelectedTeams = () => {
    const { checkedClubs, teams } = this.state;
    const selectableTeams = teams.filter(
      team => checkedClubs.indexOf(team.clubId) > -1 && team
    );
    this.setState({ selectableTeams });
  };

  getEvents = ({
    startDate = this.state.startDate,
    endDate = this.state.endDate
  } = {}) => {
    const { id } = this.props.match.params;

    this.getEventsRequest = request(
      `Events/week?filter=${encodeURI(
        JSON.stringify({
          where: {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            facilityId: id
          },
          include: [
            { relation: "divisions", scope: { order: "rank" } },
            { relation: "team", scope: { include: "club" } },
            { relation: "visitorTeamInstance", scope: { include: "club" } }
          ]
        })
      )}`
    );

    this.getEventsRequest.promise.then(events =>
      this.setState({ events }, () => {
        this.getClubsAndTeams();
        this.setFilteredEvents({});
      })
    );
  };

  getOriginalEvent = ({ id }) => {
    return request(
      `Events/${id}?filter=${encodeURI(
        JSON.stringify({
          include: [{ relation: "divisions", scope: { order: "rank" } }]
        })
      )}`,
      {
        method: "GET"
      }
    );
  };

  patchEvent = ({ id, facilityId, ...props }) => {
    return request("Events", {
      method: "PATCH",
      body: JSON.stringify({
        id,
        facilityId,
        ...props
      })
    });
  };

  resetFilters = (excludeFiltersToReset = []) => {
    const filters = {
      checkedClubs: [],
      checkedDays: [],
      checkedTypes: [],
      checkedTeams: [],
      filteredEvents: [],
      filteredTeamEvents: [],
      selectableTeams: []
    };

    this.setState(
      excludeFiltersToReset.length > 0
        ? Object.keys(filters)
            .filter(
              filterName => excludeFiltersToReset.indexOf(filterName) === -1
            )
            .reduce((total, filterName) => {
              return { ...total, [filterName]: [] };
            }, {})
        : filters
    );
  };

  findWeekEvents = ({ startDate, endDate }) => {
    const monthEvents = this.state.events;

    const weekEvents = monthEvents.filter(event => {
      const eventStartDate = event.startDate.substr(5, 5);
      return (
        eventStartDate >= startDate.toFormat("MM-dd") &&
        eventStartDate <= endDate.toFormat("MM-dd")
      );
    });

    this.setState({ events: weekEvents });
  };

  onClubHandleChange = event => {
    const checkedClubs = event.target.value;
    const { teams } = this.state;

    const selectableTeams = teams
      .filter(team => checkedClubs.indexOf(team.clubId) > -1 && team)
      .sort((prev, next) => prev.clubId - next.clubId);

    this.setFilteredEvents({ checkedClubs, selectableTeams });
  };

  teamFilter = event => {
    const checkedTeams = event.target.value;

    this.setFilteredEvents({ checkedTeams });
  };

  onTypeHandleChange = event => {
    const checkedTypes = event.target.value;

    this.setFilteredEvents({ checkedTypes });
  };

  setFilteredEvents = ({
    checkedClubs = haveElements(this.state.checkedClubs),
    checkedTeams = haveElements(this.state.checkedTeams),
    checkedTypes = haveElements(this.state.checkedTypes),
    selectableTeams = haveElements(this.state.selectableTeams)
  }) => {
    const events = this.state.events;

    const checkedTypesNames = checkedTypes
      .map(checkedType => checkedType.name)
      .reduce((reducer, currentValue) => [...reducer, ...currentValue], []);

    const filteredEvents = filterEvents({
      events,
      checkedTypesNames,
      checkedClubs,
      checkedTeams
    });

    this.setState({
      checkedClubs,
      selectableTeams,
      checkedTeams,
      checkedTypes,
      filteredEvents
    });
  };

  onDayHandleChange = event => {
    const checkedDays = event.target.value;
    this.setState({
      checkedDays
    });
  };

  onLoadSwipe = () => {
    this.onSwipeDay(DateTime.fromISO(this.state.selectedDate).weekday - 1);
    this.setState({ areCheckedDaysReset: false });
  };

  onSwipeDay = (index, type = "end") => {
    if (type !== "move") {
      const pastDay = this.state.checkedDays[0];
      const currentDay = this.daysNames[index].translate;
      const checkedDays = [currentDay];

      if (pastDay === currentDay && (index === 0 || index === 6)) {
        const currentIndex = index === 6 ? 0 : 6;
        this.setState({
          checkedDays: [this.daysNames[currentIndex].translate],
          swipeableViewsIndex: currentIndex
        });
        index === 6 ? this.setAddWeeksToNow(1) : this.setAddWeeksToNow(-1);
      } else {
        this.setState({
          checkedDays,
          swipeableViewsIndex: index
        });
      }
    }
  };

  onDateChange = ({
    selectedDate,
    shouldRequestEvents = false,
    calendarView = this.state.calendarView,
    ...props
  }) => {
    const startDate = selectedDate.startOf(calendarView);
    const endDate = selectedDate.endOf(calendarView);

    shouldRequestEvents
      ? this.getEvents({ startDate, endDate })
      : this.findWeekEvents({ startDate, endDate });

    this.setState({
      startDate,
      endDate,
      selectedDate,
      calendarView,
      ...props
    });
  };

  onHalfHourClick = ({ dayOfTheWeek, division, halfHour }) => {
    const [hour, minutes] = halfHour.split(":");
    const parsedHour = parseInt(hour);

    this.setState({
      open: true,
      event: {
        dayOfTheWeek,
        startDate: `${dayOfTheWeek.dateTime.toFormat(
          "yyyy-MM-dd"
        )}T${halfHour}`,
        endDate: `${dayOfTheWeek.dateTime.toFormat("yyyy-MM-dd")}T${
          parsedHour + 1 < 10 ? "0" : ""
        }${parsedHour + 1}:${minutes}`,
        facilityId: this.state.id,
        divisions: [division],
        halfHour
      }
    });
  };

  onEventClick = event => {
    const parsedStartDate = DateTime.fromISO(event.startDate);
    const parsedEndDate = DateTime.fromISO(event.endDate);
    const parsedRepetitionEndDate = DateTime.fromISO(event.repetitionEndDate);

    this.setState({
      open: true,
      event: {
        ...event,
        isRepeatable: event.repeatDays !== "" && event.repeatDays,
        repetitionEndDate: `${parsedRepetitionEndDate.toFormat("yyyy-MM-dd")}`,
        startDate: `${parsedStartDate.toFormat(
          "yyyy-MM-dd"
        )}T${parsedStartDate.toFormat("HH:mm")}`,
        endDate: `${parsedEndDate.toFormat(
          "yyyy-MM-dd"
        )}T${parsedEndDate.toFormat("HH:mm")}`,
        selectedTeam: event.team &&
          event.team.id && {
            value: event.team.id,
            label: event.team.name,
            ...event.team
          },
        selectedVisitorTeam: event.visitorTeam &&
          event.visitorTeam.id && {
            value: event.visitorTeam.id,
            label: event.visitorTeam.name,
            ...event.visitorTeam
          }
      }
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  callToast = ({ showToast = true, ...props }) => {
    if (showToast) {
      const toast = getToastContent({
        close: this.props.closeSnackbar,
        t: this.props.t,
        ...props
      });

      this.props.enqueueSnackbar(toast.text, toast.format);
    }
  };

  upsertEvent = ({ id, showToast = true, ...props }) => {
    const { id: facilityId } = this.state;

    id
      ? this.getOriginalEvent({ id }).promise.then(event => {
          const oldEvent = deleteObjectProp(event, "id");

          this.patchEvent({
            id,
            facilityId,
            ...props
          }).promise.then(ids => {
            const eventId = ids && ids.id;
            const lateralCreatedIds = ids && ids.lateralCreatedIDs;

            this.callToast({
              method: "update",
              undo: () => {
                if (lateralCreatedIds) {
                  lateralCreatedIds.map(lateralCreatedId =>
                    this.deleteEvent({ id: lateralCreatedId, showToast: false })
                  );
                }

                this.upsertEvent({
                  id: eventId,
                  showToast: false,
                  ...oldEvent
                });
              },
              showToast
            });

            this.setState({ open: false }, this.getEvents);
          });
        })
      : this.patchEvent({
          id,
          facilityId,
          ...props
        }).promise.then(event => {
          this.callToast({
            method: "create",
            undo: () => this.deleteEvent({ id: event.id, showToast: false }),
            showToast
          });

          this.setState({ open: false }, this.getEvents);
        });
  };

  onSubmit = ({ event, calendarEvent }) => {
    event.preventDefault();
    this.upsertEvent(calendarEvent);
  };

  deleteEvent = ({ id, date, showToast = true }) => {
    this.getOriginalEvent({ id }).promise.then(event => {
      const oldEvent = date ? event : deleteObjectProp(event, "id");

      this.deleteEventRequest = request("Events/" + id, {
        method: "DELETE",
        body: JSON.stringify({ date })
      }).promise.then(returnIds => {
        this.callToast({
          method: "delete",
          undo: () => {
            if (date) this.deleteEvent({ id: returnIds.id, showToast: false });

            this.upsertEvent({ showToast: false, ...oldEvent });
          },
          showToast
        });

        this.setState({ open: false }, this.getEvents);
      });
    });
  };

  resetToCurrentWeek = () => {
    this.resetFilters();
    this.onDateChange({
      selectedDate: DateTime.local(),
      shouldRequestEvents: true
    });
  };

  onViewSelectorChange = event => {
    const { calendarView } = this.state;
    const nextView = event.target.value;
    if (nextView !== calendarView) {
      this.onDateChange({
        selectedDate: this.state.selectedDate.startOf("month"),
        shouldRequestEvents: shouldRequestEvents(calendarView, nextView),
        calendarView: nextView
      });
    }
  };

  goToSelectedWeek = date => {
    const currentDate = DateTime.fromObject(date);
    const selectedWeek =
      currentDate.startOf("week").c.month === date.month
        ? currentDate.startOf("week")
        : currentDate.startOf("month");

    this.onDateChange({
      selectedDate: selectedWeek.startOf("week"),
      shouldRequestEvents: true,
      calendarView: "week"
    });
  };

  setAddWeeksToNow = navigation => {
    const { isDesktop } = this.props;
    const excludeFilters = isDesktop
      ? ["checkedTypes", "checkedDays"]
      : ["checkedDays"];

    this.resetFilters(excludeFilters);
    this.onDateChange({
      selectedDate: this.state.selectedDate.plus({
        [this.state.calendarView]: navigation
      }),
      shouldRequestEvents: true
    });
  };

  handleDateChange = date => {
    const { isDesktop } = this.props;

    this.resetFilters(isDesktop ? [] : ["checkedDays"]);
    this.onDateChange({
      selectedDate: DateTime.fromJSDate(date),
      shouldRequestEvents: true
    });
  };

  onPdfClick = () => {
    this.setState({ pdfModalComponent: true });
  };

  setShowModalFalse = () => {
    this.setState({ pdfModalComponent: false });
  };

  onFilterSectionOpen = () => {
    this.setState({ isFilterSectionOpen: !this.state.isFilterSectionOpen });
  };

  render() {
    const {
      divisions,
      filteredEvents,
      filteredTeamEvents,
      event,
      open,
      checkedDays,
      selectedDate,
      calendarView,
      checkedTypes,
      isFilterSectionOpen,
      swipeableViewsIndex
    } = this.state;

    const { isDesktop } = this.props;

    if (document.getElementById("mainContentPage") !== null && isDesktop) {
      document.getElementById("mainContentPage").style.overflow = "hidden";
    }

    const displayableDaysIndex =
      checkedDays.length > 0
        ? checkedDays.map(checkedDay =>
            this.daysNames.findIndex(
              dayName => dayName.translate === checkedDay
            )
          )
        : [];

    const events =
      checkedTypes.length > 0
        ? filteredEvents
        : filteredEvents.length <= 0
        ? this.state.events
        : filteredTeamEvents.length > 0
        ? filteredTeamEvents
        : filteredEvents;

    return (
      <Page>
        <div
          style={
            isDesktop
              ? { height: "100%" }
              : {
                  height: "calc(100vh - 48px)",
                  margin: "-24px",
                  marginTop: "-70px"
                }
          }
        >
          <Header
            {...this.state}
            onClubHandleChange={this.onClubHandleChange}
            handleDateChange={this.handleDateChange}
            selectedDate={selectedDate}
            onTodayClick={() => this.resetToCurrentWeek()}
            onNextWeekClick={() => this.setAddWeeksToNow(1)}
            onPreviousWeekClick={() => this.setAddWeeksToNow(-1)}
            onPdfClick={this.onPdfClick}
            setShowModalFalse={this.setShowModalFalse}
            teamFilter={this.teamFilter}
            onViewSelectorChange={this.onViewSelectorChange}
            onDateChange={this.onDateChange}
            onFilterSectionOpen={this.onFilterSectionOpen}
            eventTypes={this.eventTypes}
            daysNames={this.daysNames}
            onDayHandleChange={this.onDayHandleChange}
            onTypeHandleChange={this.onTypeHandleChange}
            displayableDaysIndex={displayableDaysIndex}
          />
          {calendarView === "week" ? (
            <CalendarWeek
              divisions={divisions}
              events={events}
              selectedDate={selectedDate}
              onEventClick={this.onEventClick}
              onHalfHourClick={this.onHalfHourClick}
              displayableDaysIndex={displayableDaysIndex}
              isFilterSectionOpen={isFilterSectionOpen}
              onSwipeDay={this.onSwipeDay}
              onLoadSwipe={this.onLoadSwipe}
              swipeableViewsIndex={swipeableViewsIndex}
            />
          ) : (
            <CalendarMonth
              selectedDate={selectedDate}
              events={events}
              goToSelectedWeek={this.goToSelectedWeek}
            />
          )}
        </div>
        {open && (
          <EventDialog
            divisions={divisions}
            event={event}
            onSubmit={this.onSubmit}
            onDelete={this.deleteEvent}
            handleClose={this.handleClose}
          />
        )}
      </Page>
    );
  }
}

export default withSnackbar(
  responsiveWrapper(withTranslation()(FacilitiesDetails))
);
