import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Security from "@material-ui/icons/Security";
import Bookmarks from "@material-ui/icons/Bookmarks";
import { withTranslation } from "react-i18next";

const MainListItems = ({ t, onClickAction }) => (
  <div>
    <ListItem
      button
      component={Link}
      to="/clubes"
      onClick={onClickAction}
      style={{ paddingLeft: 25 }}
    >
      <ListItemIcon>
        <Security />
      </ListItemIcon>
      <ListItemText primary={t("Entidades")} />
    </ListItem>
    <ListItem
      button
      component={Link}
      to="/instalaciones"
      onClick={onClickAction}
      style={{ paddingLeft: 25 }}
    >
      <ListItemIcon>
        <AccountBalance />
      </ListItemIcon>
      <ListItemText primary={t("Instalaciones")} />
    </ListItem>
    <ListItem
      button
      component={Link}
      to="/modalidades"
      onClick={onClickAction}
      style={{ paddingLeft: 25 }}
    >
      <ListItemIcon>
        <Bookmarks />
      </ListItemIcon>
      <ListItemText primary={t("Modalidades")} />
    </ListItem>
  </div>
);
export default withTranslation()(MainListItems);
