import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControl,
  InputLabel,
  TextField,
  Input
} from "@material-ui/core";
import { Select } from "ui";
import TabContainer from "./TabContainer";
import isDataValidated from "utils/matchValidation";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import esLocale from "date-fns/locale/es";
import { formatDate } from "utils/date";
import { getFilteredItems } from "utils/reducers";
import { isNullOrEmpty } from "utils/calendar";

function TabContainerMatch({
  matchDisabledContent,
  theme,
  onParentSubmit,
  onStartHourChange,
  onEndHourChange,
  onInputChange,
  handleClose,
  requiredError,
  startDateSubmitted,
  startDateSubmittedHour,
  endDateSubmittedHour,
  id,
  startDate,
  endDate,
  divisions,
  teams,
  clubs,
  availableStates,
  selectedClub,
  selectedVisitorClub,
  selectedTeam,
  selectedVisitorTeam,
  eventState,
  matchFieldRequiredError,
  renderDivisionSelect,
  localTeam,
  localCloset = "",
  localDressRoom = "",
  visitorTeam = "",
  visitorDressRoom = "",
  visitorCloset = "",
  category = "",
  renderDeleteEvent,
  eventNotes,
  t,
  setSubmitButtonDisabled,
  submitButtonDisabled
}) {
  const todayDate = formatDate(startDateSubmitted, "yyyy-MM-dd");
  const [matchDate, setMatchDate] = useState(new Date(todayDate));
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filteredVisitorTeams, setFilteredVisitorTeams] = useState([]);

  const localeMap = {
    es: esLocale
  };

  const locale = "es";

  useEffect(() => {
    if (!isNullOrEmpty(selectedTeam) && !isNullOrEmpty(id)) {
      onInputChange({
        target: {
          name: "selectedClub",
          value: {
            value: selectedTeam.club.id,
            label: selectedTeam.club.name,
            ...selectedTeam.club
          }
        }
      });
    }

    if (!isNullOrEmpty(selectedVisitorTeam) && !isNullOrEmpty(id)) {
      onInputChange({
        target: {
          name: "selectedVisitorClub",
          value: {
            value: selectedVisitorTeam.club.id,
            label: selectedVisitorTeam.club.name,
            ...selectedVisitorTeam.club
          }
        }
      });
    }

    if (localTeam && !localTeam.id) {
      onInputChange({
        target: {
          name: "selectedClub",
          value: {
            value: localTeam,
            label: localTeam
          }
        }
      });
    }

    if (visitorTeam && !visitorTeam.id) {
      onInputChange({
        target: {
          name: "selectedVisitorClub",
          value: {
            value: visitorTeam,
            label: visitorTeam
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTeam && selectedTeam.club) {
      setFilteredTeams(
        teams.filter(team => team.club.id === selectedTeam.club.id)
      );
    }

    if (selectedVisitorTeam && selectedVisitorTeam.club) {
      setFilteredVisitorTeams(
        teams.filter(team => team.club.id === selectedVisitorTeam.club.id)
      );
    }
  }, [teams]);

  const ShowMatchDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        <DatePicker
          format="dd/MM/yyyy"
          autoOk
          value={matchDate}
          label={t("Editar fecha")}
          onChange={setMatchDate}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    const event = {
      divisions,
      selectedClub,
      selectedVisitorClub,
      selectedTeam,
      selectedVisitorTeam,
      eventState,
      localCloset,
      localDressRoom,
      visitorTeam,
      visitorDressRoom,
      visitorCloset,
      eventNotes,
      category
    };
    const dataValidation = isDataValidated(event);

    if (dataValidation.valid) {
      setSubmitButtonDisabled(true);

      onParentSubmit({
        event: e,
        calendarEvent: {
          id,
          startDate:
            formatDate(matchDate.toISOString(), "yyyy-MM-dd'T'") +
            startDateSubmittedHour,
          endDate:
            formatDate(matchDate.toISOString(), "yyyy-MM-dd'T'") +
            endDateSubmittedHour,
          divisions,
          eventState: eventState,
          teamId: selectedTeam && selectedTeam.id ? selectedTeam.id : null,
          localTeam:
            selectedClub && !selectedClub.id ? selectedClub.value : null,
          localDressRoom,
          localCloset,
          visitorTeamId:
            selectedVisitorTeam && selectedVisitorTeam.id
              ? selectedVisitorTeam.id
              : null,
          visitorTeam:
            selectedVisitorClub && !selectedVisitorClub.id
              ? selectedVisitorClub.value
              : null,
          visitorDressRoom,
          visitorCloset,
          eventNotes,
          category,
          type: "match"
        }
      });
    } else {
      toggleErrors(
        matchFieldRequiredError,
        dataValidation.errors,
        requiredError
      );
    }
  };

  return (
    <TabContainer dir={theme.direction} notDisplayable={matchDisabledContent}>
      <DialogTitle id="form-dialog-title">
        {id ? "Editar" : "Crear"} {t("Partido")}{" "}
        {formatDate(startDateSubmitted, "dd/MM/yyyy")}
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "hidden", minHeight: 500, minWidth: 550 }}
      >
        {id && <ShowMatchDate />}
        <form onSubmit={onSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  name="startDate"
                  type="time"
                  label={t("Hora Inicio")}
                  value={formatDate(startDate, "HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: { step: 900, min: "06:00", max: "22:45" }
                  }}
                  onChange={onStartHourChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  name="endDate"
                  type="time"
                  label={t("Hora Fin")}
                  value={formatDate(endDate, "HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      step: 900,
                      min: formatDate(startDate, "HH:mm"),
                      max: "23:00"
                    }
                  }}
                  onChange={onEndHourChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {renderDivisionSelect("matchFieldRequiredError", divisions)}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t("Estado")}</InputLabel>
              <Select
                options={availableStates}
                value={
                  eventState
                    ? {
                        value: eventState,
                        label: t(
                          availableStates.find(
                            availableState =>
                              availableState.value === eventState
                          ).label
                        )
                      }
                    : null
                }
                placeholder={t("Selecciona un estado...")}
                onChange={value =>
                  onInputChange({
                    target: {
                      name: "eventState",
                      value: value ? value.value : ""
                    }
                  })
                }
                isClearable
              />
            </Grid>
            <CreatableEntityWithTeam
              label={`${t("Entidad")} Local`}
              teamLabel={`${t("Equipo")} Local`}
              entities={clubs}
              teams={filteredTeams}
              selectedEntity={selectedClub}
              selectedTeam={selectedTeam}
              entityHasError={matchFieldRequiredError.selectedClubRequiredError}
              teamHasError={matchFieldRequiredError.selectedTeamRequiredError}
              entityPlaceholder={t("Selecciona una entidad...")}
              teamPlaceholder={`${t("Equipo")} Local`}
              onEntityChange={value => {
                onInputChange({
                  target: { name: "selectedClub", value }
                });
                onInputChange({
                  target: { name: "selectedTeam", value: null }
                });
                setFilteredTeams(
                  value
                    ? getFilteredItems(
                        teams,
                        value,
                        (team, value) => value.value === team.clubId
                      )
                    : []
                );
              }}
              onTeamChange={value => {
                onInputChange({
                  target: { name: "selectedTeam", value }
                });
              }}
              requiredMessage={t("Campo requerido")}
            />
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="localDressRoom">Vestidor Local</InputLabel>
                <Input
                  id="localDressRoom"
                  name="localDressRoom"
                  autoComplete="localDressRoom"
                  value={localDressRoom}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="localCloset">
                  {t("Armario")} Local
                </InputLabel>
                <Input
                  id="localCloset"
                  name="localCloset"
                  autoComplete="localCloset"
                  value={localCloset}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <CreatableEntityWithTeam
              label={`${t("Entidad")} ${t("Visitant")}`}
              teamLabel={`${t("Equipo")} ${t("Visitante")}`}
              entities={clubs}
              teams={filteredVisitorTeams}
              selectedEntity={selectedVisitorClub}
              selectedTeam={selectedVisitorTeam}
              entityHasError={
                matchFieldRequiredError.selectedVisitorClubRequiredError
              }
              teamHasError={
                matchFieldRequiredError.selectedselectedVisitorTeamRequiredError
              }
              entityPlaceholder={t("Selecciona una entidad...")}
              teamPlaceholder={`${t("Equipo")} ${t("Visitante")}`}
              onEntityChange={value => {
                onInputChange({
                  target: { name: "selectedVisitorClub", value }
                });
                onInputChange({
                  target: { name: "selectedVisitorTeam", value: null }
                });
                setFilteredVisitorTeams(
                  value
                    ? getFilteredItems(
                        teams,
                        value,
                        (team, value) => value.value === team.clubId
                      )
                    : []
                );
              }}
              onTeamChange={value => {
                onInputChange({
                  target: { name: "selectedVisitorTeam", value }
                });
              }}
              requiredMessage={t("Campo requerido")}
            />
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="visitorDressRoom">
                  Vestidor {t("Visitante")}
                </InputLabel>
                <Input
                  id="visitorDressRoom"
                  name="visitorDressRoom"
                  autoComplete="visitorDressRoom"
                  value={visitorDressRoom}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="visitorCloset">
                  {t("Armario")} {t("Visitante")}
                </InputLabel>
                <Input
                  id="visitorCloset"
                  name="visitorCloset"
                  autoComplete="visitorCloset"
                  value={visitorCloset}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel
                  htmlFor="category"
                  error={matchFieldRequiredError.categoryRequiredError}
                >
                  {t("Categoría")}
                </InputLabel>
                <Input
                  id="category"
                  name="category"
                  autoComplete="category"
                  value={category}
                  onChange={onInputChange}
                  error={matchFieldRequiredError.categoryRequiredError}
                  required
                />
                {matchFieldRequiredError.categoryRequiredError && (
                  <FormHelperText style={{ color: "#F44336" }}>
                    {t("Campo requerido")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="eventNotes">{t("Notas")}</InputLabel>
                <Input
                  name="eventNotes"
                  autoComplete="eventNotes"
                  multiline={true}
                  rows={6}
                  value={eventNotes}
                  onChange={onInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {renderDeleteEvent()}
        <Button onClick={handleClose} color="primary">
          {t("Cancelar")}
        </Button>
        <Button
          onClick={onSubmit}
          type="submit"
          color="primary"
          disabled={submitButtonDisabled}
        >
          {id ? t("Actualizar") : "Crear"}
        </Button>
      </DialogActions>
    </TabContainer>
  );
}

const CreatableEntityWithTeam = ({
  label,
  teamLabel,
  entities,
  teams,
  selectedEntity,
  selectedTeam,
  entityHasError,
  teamHasError,
  entityPlaceholder,
  teamPlaceholder,
  onEntityChange,
  onTeamChange,
  requiredMessage
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <InputLabel style={{ marginTop: 16 }} error={entityHasError}>
          {label}
        </InputLabel>
        <Select
          creatable={true}
          options={entities}
          value={selectedEntity}
          placeholder={entityPlaceholder}
          onChange={onEntityChange}
          error={entityHasError}
          isClearable
          required
        />
        {entityHasError && (
          <FormHelperText style={{ color: "#F44336" }}>
            {requiredMessage}
          </FormHelperText>
        )}
      </Grid>
      {selectedEntity && selectedEntity.id && (
        <Grid item xs={12}>
          <InputLabel error={teamHasError}>{teamLabel}</InputLabel>
          <Select
            options={teams}
            value={selectedTeam}
            placeholder={teamPlaceholder}
            onChange={onTeamChange}
            error={teamHasError}
            isClearable
          />
          {teamHasError && (
            <FormHelperText style={{ color: "#F44336" }}>
              {requiredMessage}
            </FormHelperText>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};

function toggleErrors(matchFieldRequiredError, errors, requiredError) {
  const matchFieldRequiredErrorMuted = Object.keys(matchFieldRequiredError)
    .map(key => {
      const isInErrors = errors.findIndex(error => error.field === key) > -1;
      return { [key]: isInErrors };
    })
    .reduce((reducer, item) => {
      return { ...reducer, ...item };
    }, {});

  requiredError({ matchFieldRequiredError: matchFieldRequiredErrorMuted });
}

export default TabContainerMatch;
