import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, InputLabel } from "@material-ui/core";
import { Modal, Select } from "./";
import { DateTime } from "luxon";
import { CloudDownload } from "@material-ui/icons/";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import esLocale from "date-fns/locale/es";
import { withTranslation } from "react-i18next";
import request from "utils/request";
import { mediaQueryBreakpoints } from "utils/responsive";

const BASE_API_URL = process.env.REACT_APP_API_URL;

const localeMap = {
  es: esLocale
};

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "absolute",
    background: "#FAFAFA",
    width: 400,
    height: "auto",
    outline: "none",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "15px",
    [theme.breakpoints.down(mediaQueryBreakpoints.TABLET)]: {
      width: 300
    }
  },
  moreWeeksTitleAndButton: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  moreWeeksDatePickers: {
    display: "flex",
    flex: 1,
    margin: 15
  },
  pdfOption: {
    flex: 1,
    display: "flex",
    alignItems: "center"
  },
  pdfOptionTitle: {
    flex: 3
  },
  pdfOptionButton: {
    flex: 1
  }
});

const flatArray = collection => collection.reduce((r, i) => [...r, ...i], []);

class PdfModal extends React.Component {
  state = {
    initialDay: this.props.startDate.startOf("week"),
    finalDay: this.props.endDate.endOf("week"),
    facilities: [],
    selectedFacilities: []
  };

  locale = "es";
  setLocale = "es";
  checkedTypes =
    this.props.checkedTypes.length > 0
      ? this.props.checkedTypes.map(type => type.name)
      : [];
  checkedClubs =
    this.props.checkedClubs.length > 0 ? this.props.checkedClubs : [];
  checkedTeams =
    this.props.checkedTeams.length > 0 ? this.props.checkedTeams : [];

  displayableDaysIndex = this.props.isMobile
    ? []
    : this.props.displayableDaysIndex.length > 0
    ? this.props.displayableDaysIndex.sort((a, b) => a - b)
    : [];

  componentDidMount() {
    request("Facilities").promise.then(facilities =>
      this.setState({
        facilities: facilities
          .filter(({ id }) => id !== this.props.id)
          .map(({ id, name, ...facility }) => ({
            name,
            label: name,
            value: id,
            ...facility
          }))
      })
    );
  }

  setWeekDatePicker({ label, value, name, ...props }) {
    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={localeMap[this.locale]}
      >
        <DatePicker
          id={name}
          name={name}
          format="dd/MM/yyyy"
          label={this.props.t(label)}
          autoOk
          value={value}
          invalidDateMessage={this.props.t("Fecha incorrecta")}
          onChange={date => this.handleDateChange({ name, date })}
          {...props}
        />
      </MuiPickersUtilsProvider>
    );
  }

  handleDateChange = ({ name, date }) => {
    this.setState({ [name]: DateTime.fromJSDate(date) });
  };

  getDownloadURL = type =>
    `${BASE_API_URL}Events/${type}/download?startDate=${this.state.initialDay.toISODate()}&endDate=${this.state.finalDay.toISODate()}&filter=${encodeURI(
      JSON.stringify({
        facilities: [
          ...this.state.selectedFacilities.map(({ value }) => value),
          this.props.id
        ],
        checkedTypes: flatArray(this.checkedTypes),
        checkedClubs: this.checkedClubs,
        displayableDays: this.displayableDaysIndex,
        checkedTeams: this.checkedTeams
      })
    )}`;

  render() {
    const { classes, t, setShowModal } = this.props;
    const { facilities, selectedFacilities } = this.state;

    return (
      <Modal onModalContainerClick={() => setShowModal()}>
        <div className={classes.paper}>
          <div
            className={classes.pdfOption}
            style={{
              borderBottom: "1px solid rgba(200, 200, 200, 0.7)",
              paddingBottom: 10,
              marginBottom: 10,
              flexDirection: "column",
              alignItems: "stretch"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20
              }}
            >
              <Typography
                className={classes.pdfOptionTitle}
                style={{ fontSize: "1rem" }}
              >
                {t("Vista Tabla")}
              </Typography>
              <Button
                className={classes.pdfOptionButton}
                style={{ height: "40px" }}
                href={this.getDownloadURL("events")}
              >
                <CloudDownload
                  style={{
                    marginLeft: "4px",
                    minHeight: "10px",
                    color: "#757575"
                  }}
                />
              </Button>
            </div>
            <InputLabel style={{ marginLeft: 15 }}>
              {t("Instalaciones")} Extra
            </InputLabel>
            <div className={classes.moreWeeksDatePickers}>
              <Select
                options={facilities}
                value={selectedFacilities}
                placeholder={`${t("Selecciona las instalaciones")}...`}
                onChange={selectedFacilities =>
                  this.setState({
                    selectedFacilities: selectedFacilities.map(
                      ({ label, ...facility }) => ({
                        ...facility,
                        label:
                          label.length > 20
                            ? `${label.substring(0, 20)}...`
                            : label
                      })
                    )
                  })
                }
                isClearable
                isMulti
              />
            </div>
          </div>
          <div
            className={classes.pdfOption}
            style={{
              flexDirection: "column",
              alignItems: "stretch",
              marginBottom: "20px"
            }}
          >
            <div className={classes.moreWeeksTitleAndButton}>
              <Typography className={classes.pdfOptionTitle}>
                {t("Vista Calendario")}
              </Typography>
              <Button
                className={classes.pdfOptionButton}
                style={{ height: "40px" }}
                href={this.getDownloadURL("week")}
              >
                <CloudDownload
                  style={{
                    marginLeft: "4px",
                    minHeight: "10px",
                    color: "#757575"
                  }}
                />
              </Button>
            </div>
            <div className={classes.moreWeeksDatePickers}>
              {this.setWeekDatePicker({
                label: "Fecha inicial",
                value: this.state.initialDay,
                name: "initialDay"
              })}
            </div>
            <div className={classes.moreWeeksDatePickers}>
              {this.setWeekDatePicker({
                label: "Fecha final",
                value: this.state.finalDay,
                name: "finalDay",
                minDate: this.state.initialDay,
                minDateMessage: this.props.t("Debe ser posterior a la inicial")
              })}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(withStyles(styles)(PdfModal));
